import axios from "axios";
import { useEffect, useState, useCallback } from "react";
import { Link, useParams, useHistory} from "react-router-dom";
import BrAvatar from "./Avatar";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Button from "../Input/Button";
import ProfileAccordions from "./ProfileAccordions";
import SelectAvatarModal from "../Modals/SelectAvatarModal";
import SelectNationalityModal from "../Modals/SelectNationalityModal";
import CountriesMap from "../Profile/CountriesMap";
import HiddenProfileMessage from "./HiddenProfileMessage";

const { REACT_APP_API_URL } = process.env;

export default function Profile(props) {
    const { user, isParent, showNotification, theme } = props;
    const { userId } = useParams();
    const history = useHistory();
    
    const useStyles = makeStyles({
        root: {
            minWidth: 275,
            minHeight: 500, 
        },
        bullet: {
            minHeight: "100px",
            margin: "0 2px",
            transform: "scale(0.8)"
        },
        title: {
            fontSize: 32,
            fontFamily: "GoodUnicorn",
            color: theme.primaryColor
        },
        avatar: {
            marginLeft: "37.5%",
            marginTop: "5%",
            width: "20%",
            height: "20%"
        },
        pos: {
            marginBottom: 12
        },
        rewardCard: {
            backgroundColor: "#efeaff"
        },
        heading: {
            fontSize: 16,
            flexBasis: "33.33%",
            flexShrink: 0
        },
        hobbyCard: {
            backgroundColor: "#dbf2d1"
        },
        videosCard: {
            backgroundColor: "#ffe5e5"
        },
        videoCardContainer: {
            margin: "4px",
            width: "80%",
            height: "75%"
        },
        playIcon: {
            color: "#eb4c8a",
            cursor: "pointer",
            position: "absolute",
            top: "20%",
            left: "25%",
            transform: "translate(-25%, -25%)"
        },
        imagesCard: {
            backgroundColor: "#d6f1fe"
        },
        images: {
            margin: "4px"
        },
        subCardReward: {
            width: "50%",
            minHeight: "40px",
            marginTop: "20%",
            marginLeft: "10%",
            backgroundColor: "#937cff"
        },
        subCardHobbies: {
            width: "50%",
            minHeight: "40px",
            marginTop: "20%",
            marginLeft: "10%",
            backgroundColor: "#4bbe0d"
        },
        hobbyIcon: {
            width: "80%",
            minHeight: "40px",
            marginTop: "20%",
            backgroundColor: "#dbf2d1"
        },
        hobbyLink: {
            marginBottom: "-10px",
            color: "#4bbe0d"
        },
        subCardVideos: {
            width: "50%",
            minHeight: "40px",
            marginTop: "20%",
            marginLeft: "10%",
            backgroundColor: "#fe787f",
        },
        subCardImages: {
            width: "50%",
            minHeight: "40px",
            marginTop: "20%",
            marginLeft: "10%",
            backgroundColor: "#2cbcf8"
        },
        actionBtn: {
            marginLeft: "0%",
            width: "50%",
            height: "20%"
        },
    });
    
    const classes = useStyles();
    
    const [profileData, setProfileData] = useState({
        profileUser: {},
        myFriends: [],
        myPendingFriends: [],
        userHobbies: [],
        userImages: [],
        userVideos: [],
        awardCount: 0
    });
    
    const [showAvatarModal, setShowAvatarModal] = useState(false);
    const [showNationalityModal, setShowNationalityModal] = useState(false);
    
    const fetchProfileData = useCallback(async () => {
        try {
            // Ensure we have the token
            const token = window.localStorage.getItem("JWT_TOKEN");
            if (!token) {
                console.error("No auth token found");
                return;
            }
            
            // Set request config with auth header
            const requestConfig = {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            };
            
            // Use Promise.all to make parallel requests
            const [
                userProfileResult, 
                friendsResult, 
                pendingFriendsResult, 
                hobbiesResult, 
                imagesResult, 
                videosResult
            ] = await Promise.all([
                axios.get(`${REACT_APP_API_URL}/user/${userId}`, requestConfig),
                axios.get(`${REACT_APP_API_URL}/friends/${userId}`, requestConfig),
                axios.get(`${REACT_APP_API_URL}/pending_friends/${userId}`, requestConfig),
                axios.get(`${REACT_APP_API_URL}/hobbies/${userId}`, requestConfig),
                axios.get(`${REACT_APP_API_URL}/uploads/${userId}?type=image`, requestConfig),
                axios.get(`${REACT_APP_API_URL}/uploads/${userId}?type=video`, requestConfig),
            ]);
            
            const awardCount = userProfileResult.data.posts
                ? userProfileResult.data.posts.reduce((prev, current) => prev + current.awards.length, 0)
                : 0;
            
            setProfileData({
                profileUser: userProfileResult.data,
                myFriends: friendsResult.data,
                myPendingFriends: pendingFriendsResult.data,
                userHobbies: hobbiesResult.data,
                userImages: imagesResult.data,
                userVideos: videosResult.data,
                awardCount
            });
        } catch (error) {
            console.error("Error fetching profile data:", error);
            showNotification("Failed to load profile data", "error");
        }
    }, [userId, showNotification]);
    
    useEffect(() => {
        fetchProfileData();
    }, [fetchProfileData]);
    
    const requestFriend = async () => {
        try {
            // Get the authentication token
            const token = window.localStorage.getItem("JWT_TOKEN");
            if (!token) {
                console.error("No auth token found");
                showNotification("Authentication error", "error");
                return;
            }
            
            // Make the request with explicit auth header
            const result = await axios({
                method: 'POST',
                url: `${REACT_APP_API_URL}/friends/${userId}`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });
            
            if (result.status === 200) {
                showNotification("Friend requested, awaiting parental approval", "success");
                fetchProfileData();
            }
        } catch (error) {
            console.error("Friend request error:", error);
            
            // Handle 401 errors specifically
            if (error.response?.status === 401) {
                showNotification("Authentication error - please log in again", "error");
                setTimeout(() => {
                    // Clear storage and redirect to login
                    window.localStorage.clear();
                    window.location.href = '/';
                }, 2000);
            } else {
                showNotification("Failed to send friend request", "error");
            }
        }
    };
    
    const cancelRequest = async (targetId) => {
        try {
            const token = window.localStorage.getItem("JWT_TOKEN");
            
            await axios({
                method: 'DELETE',
                url: `${REACT_APP_API_URL}/friends/${userId}/${targetId}`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            
            fetchProfileData();
        } catch (error) {
            console.error("Error canceling request:", error);
            showNotification("Failed to cancel friend request", "error");
        }
    };
    
    const FriendActions = () => {
        const { profileUser, myFriends, myPendingFriends } = profileData;
        
        if (isParent || user.isMentor || (user?.settings && user?.settings?.profile_hidden === 1)) {
            return null;
        }
        
        if (!isParent && profileUser.settings && profileUser.settings.profile_hidden === 1) {
            return <div style={{ textAlign: "center", width: "100%" }}>This user does not accept friend requests.</div>;
        }
        
        const isFriend = myFriends.some(friend => friend.id === profileUser.id);
        const isPendingFriend = myPendingFriends.some(friend => friend.id !== profileUser.id);
        
        
        if (!isParent && isFriend.length > 0) {
            return (
                <Link style={{ textDecoration: "none" }} to={`/messenger/${userId}/${userId}`}>
                    <div style={{ textAlign: "center", width: "100%" }}>
                        <Button theme={theme} className={classes.actionBtn}>Chat</Button>
                    </div>
                </Link>
            );
        }
        
        if (!isParent && isPendingFriend) {
            myPendingFriends.map(friend => {
                if (friend.id !== profileUser.id) {
                    return (
                        <div style={{ textAlign: "center", width: "100%" }}>
                            <Button theme={theme} onClick={() => cancelRequest(friend.id)} className={classes.actionBtn}>Cancel Request</Button>
                        </div>
                    );
                }
            });
        }
        
        return (
            <div style={{ textAlign: "center", width: "100%", marginTop: "10px" }}>
                <Button theme={theme} onClick={requestFriend} className={classes.actionBtn}>Request Friend</Button>
            </div>
        );
    };
    
    const calculateAge = (dob) => {
        const ageDifMs = Date.now() - new Date(dob).getTime();
        const ageDate = new Date(ageDifMs);
        return Math.abs(ageDate.getUTCFullYear() - 1970);
    };
    
    const { profileUser, awardCount } = profileData;
    console.log("🚀 ~ file: Profile.jsx:254 ~ Profile ~ profileUser:", profileUser);
    
    const navigateToDashboard = () => {
        history.push("/dashboard/home");
    };
    
    if (profileUser?.settings?.profile_hidden === 1 && 
        user.id !== profileUser.id && // Allow the profile owner to see their own profile
        !user.isMentor) { // Allow mentors to see all profiles
        return <HiddenProfileMessage theme={theme} history={history} />;
    } else {
        return (
            <>
                <div>
                    <Grid container spacing={2}>
                        <Grid item xs={1} />
                        <Grid className={classes.title} item xs={11}>
                            Profile
                        </Grid>
                        <Grid item xs={1} />
                        <Grid item xs={3}>
                            <Card raised className={classes.root}>
                                <div style={{ textAlign: "center", margin: "5px", fontWeight: "bold" }}>
                                    {profileUser.name}
                                </div>
                                
                                {profileUser.dob && (
                                    <div style={{ textAlign: "center", margin: "5px", fontSize: "14px" }}>
                                        {calculateAge(profileUser.dob) + " Years Old"}
                                    </div>
                                )}
                                
                                {profileUser.avatar && (
                                    <BrAvatar
                                        avatarStyles={{ width: "50%", height: "50%" }}
                                        user={profileUser}
                                        theme={theme}
                                        className={classes.avatar}
                                    />
                                )}
                                {(profileUser.id === userId && user.isKid) && (
                                    <div style={{ textAlign: "center", width: "100%", marginTop: 5 }}>
                                        <Button
                                            theme={theme}
                                            onClick={() => setShowAvatarModal(true)}
                                            className={classes.actionBtn}
                                        >
                                            Change Avatar
                                        </Button>
                                    </div>
                                )}
                                
                                {/* Ensure friend actions are not displayed for the profile owner */}
                                {!isParent && profileUser.id !== user.id && (
                                    <FriendActions />
                                )}
                                
                                <div style={{ textAlign: "center", width: "100%", marginTop: 5 }}>
                                    {profileUser.id === userId && user.isKid && (
                                        <div style={{ textAlign: "center", width: "100%", marginTop: 5, marginBottom: 5 }}>
                                            <Button
                                                theme={theme}
                                                onClick={() => setShowNationalityModal(true)}
                                                className={classes.actionBtn}
                                            >
                                                Nationality
                                            </Button>
                                        </div>
                                    )}
                                    
                                    {profileUser && (
                                        <>
                                            <img
                                                src={`https://flagcdn.com/16x12/${profileUser?.nationality?.toLowerCase() ?? 'us'}.png`}
                                                srcSet={`https://flagcdn.com/32x24/${profileUser?.nationality?.toLowerCase() ?? 'us'}.png 2x,
                                                    https://flagcdn.com/48x36/${profileUser?.nationality?.toLowerCase() ?? 'us'}.png 3x`}
                                                width="16"
                                                height="12"
                                                alt={`Flag of ${profileUser?.nationality ?? 'US'}`}
                                            />
                                            {" "}
                                            {CountriesMap[profileUser?.nationality?.toUpperCase() ?? 'US']}
                                        </>
                                    )}
                                </div>
                            </Card>
                        </Grid>
                        
                        <Grid item xs={6}>
                            <Card raised className={classes.root}>
                                <ProfileAccordions
                                    userId={userId}
                                    user={profileUser}
                                    awardCount={awardCount}
                                />
                            </Card>
                        </Grid>
                    </Grid>
                    <SelectAvatarModal
                        user={profileUser}
                        theme={theme}
                        open={showAvatarModal}
                        handleClose={() => setShowAvatarModal(false)}
                    />
                    <SelectNationalityModal
                        user={profileUser}
                        theme={theme}
                        open={showNationalityModal}
                        handleClose={() => setShowNationalityModal(false)}
                    />
                </div>
                
                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                    <Button
                        onClick={navigateToDashboard}
                        theme={theme}
                        style={{ 
                            fontWeight: 'bold',
                            padding: '10px 20px',
                            fontSize: '16px'
                        }}
                    >
                        Dashboard
                    </Button>
                </div>
            </>
        );
    }
};