import { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Badge from "@material-ui/core/Badge";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import BrAvatar from "../Profile/Avatar";
import Home from "../Profile/Home";
import Journal from "../Profile/Journal";
import XCPals from "../Profile/XCPals";
import NavTabs from "./NavTabs";
import ParentsPortal from "../Profile/ParentsPortal";
import LifeTopics from "../Profile/LifeTopics";
import OnlineResources from "../Profile/OnlineResources";
import { usePusherConnection } from '../hooks/usePusherConnection';

const { REACT_APP_API_URL } = process.env;

const useStyles = makeStyles((theme) => ({
    badge: {
        right: 10,
        top: 11,
        border: `2px solid ${theme.primaryColor}`,
        padding: "0 4px",
        justifyContent: "flex-end",
    },
    notifBadge: {
        right: 1,
        top: 15,
        border: `2px solid ${theme.primaryColor}`,
        padding: "0 4px",
        justifyContent: "flex-end",
    },
    root: {
        flexGrow: 1,
        justifyContent: "center",
    },
    topNav: {
        height: "75px",
        width: "100%",
    },
    control: {
        padding: theme.spacing(2),
    },
    loadingContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
    },
    menuPaper: {
        opacity: 0,
        transform: 'scale(0.95)',
        transition: 'opacity 200ms ease-in-out, transform 200ms ease-in-out',
    },
    menuPaperVisible: {
        opacity: 1,
        transform: 'scale(1)',
    }
}));

export default function Dashboard(props) {
    const settings = JSON.parse(window.localStorage.getItem('SETTINGS') || 'null');
    
    const { user, isParent, isMentor, showNotification, theme, isLoading: propsIsLoading, initialTab } = props;
    
    const [value, setValue] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [messageCount, setMessageCount] = useState(user?.message_notifs?.length || 0);
    
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    
    useEffect(() => {
        if (!propsIsLoading && user) {
            setMessageCount(user.message_notifs?.length || 0);
            setIsLoading(false);
        }
    }, [user, propsIsLoading]);
    
    const handleTabChange = useCallback((newValue) => {
        setValue(newValue);
    }, []);
    
    useEffect(() => {
        const path = location.pathname.split('/').pop();
        switch (path) {
            case 'home':
                setValue(0);
                break;
            case 'life_topics':
                setValue(1);
                break;
            case 'xc_pals':
                setValue(2);
                break;
            case 'journal':
                setValue(3);
                break;
            case 'resources':
                setValue(4);
                break;
            case 'parents':
                setValue(5);
                break;
            default:
                setValue(0);
        }
    }, [location]);
    
    const { isConnected, subscribeToMenuNotifications } = usePusherConnection(user?.id);
    
    useEffect(() => {
        if (isConnected && user?.id) {
            const cleanup = subscribeToMenuNotifications((e) => {
                if (e.message) {
                    setMessageCount(prevCount => prevCount + 1);
                }
            });
            
            return () => {
                if (cleanup) cleanup();
            };
        }
    }, [isConnected, user?.id, subscribeToMenuNotifications]);
    
    const SimpleMenu = () => {
        const [open, setOpen] = useState(false);
        const [notifCount, setNotifCount] = useState(0);
        const anchorRef = useRef(null);
        const { isConnected, subscribeToMenuNotifications } = usePusherConnection(user);
        const classes = useStyles();
        
        useEffect(() => {
            const fetchNotificationCount = async () => {
                try {
                    if (isParent) {
                        const selectedChildId = window.localStorage.getItem('SELECTED_CHILD_ID');
                        const result = await axios.get(
                            `${REACT_APP_API_URL}/pending_friends/${selectedChildId}`,
                            {
                                headers: {
                                    'Authorization': `Bearer ${window.localStorage.getItem('JWT_TOKEN')}`
                                }
                            }
                        );
                        setNotifCount(result.data.length);
                    }
                } catch (error) {
                    console.error('Error fetching notification count:', error);
                }
            };
            
            fetchNotificationCount();
            
            if (isConnected && isParent) {
                const cleanup = subscribeToMenuNotifications((data) => {
                    const selectedChildId = window.localStorage.getItem('SELECTED_CHILD_ID');
                    if (data.child_id === selectedChildId) {
                        setNotifCount(prev => prev + 1);
                        showNotification(
                            `New friend request for your child from ${data.friend_name || 'someone'}`,
                            'info'
                        );
                    }
                });
                
                return cleanup;
            }
        }, [user, isConnected, subscribeToMenuNotifications]);
        
        const handleToggle = () => {
            setOpen((prevOpen) => !prevOpen);
        };
        
        const handleClose = (event) => {
            if (anchorRef.current && anchorRef.current.contains(event.target)) {
                return;
            }
            setOpen(false);
        };
        
        const handleFriendRequests = (event) => {
            handleClose(event);
            history.push('/dashboard/xc_pals');
        };
        
        const handleLogout = (event) => {
            handleClose(event);
            Object.keys(localStorage).forEach(key => localStorage.removeItem(key));
            console.log(`Dashboard::handleLogout() - user logged out. Redirecting to ${REACT_APP_API_URL.includes('localhost') ? 'http://localhost:3000/' : 'https://www.bridgepalkids.com/'}`);
            window.location.href = REACT_APP_API_URL.includes('localhost') ? 'http://localhost:3000/' : 'https://www.bridgepalkids.com/';
        };
        
        return (
            <Badge
                badgeContent={notifCount > 0 ? notifCount : null}
                classes={{ badge: classes.badge }}
                color="primary"
                overlap="rectangular"
            >
                <IconButton
                    ref={anchorRef}
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    style={{ color: theme.primaryColor }}
                    onClick={handleToggle}
                >
                    <MoreVertIcon />
                </IconButton>
                <Popper
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    placement="bottom-end"
                    disablePortal
                    style={{ zIndex: 1300 }}
                >
                    <Paper 
                        className={`${classes.menuPaper} ${open ? classes.menuPaperVisible : ''}`}
                        elevation={4}
                    >
                        <ClickAwayListener onClickAway={handleClose}>
                            <MenuList autoFocusItem={open} id="menu-list-grow">
                                {isParent && notifCount > 0 && (
                                    <MenuItem onClick={handleFriendRequests}>
                                        Friend Requests ({notifCount})
                                    </MenuItem>
                                )}
                                <MenuItem onClick={handleLogout}>
                                    Logout
                                </MenuItem>
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Popper>
            </Badge>
        );
    };
    
    const PANELS = [
        <Home user={user} isParent={isParent} isMentor={isMentor} theme={theme} showNotification={showNotification} />,
        <LifeTopics user={user} isParent={isParent} isMentor={isMentor} theme={theme} />,
        <XCPals user={user} isParent={isParent} isMentor={isMentor} theme={theme} showNotification={showNotification} />,
        <Journal user={user} isParent={isParent} isMentor={isMentor} theme={theme} />,
        <OnlineResources user={user} isParent={isParent} isMentor={isMentor} theme={theme} />,
        <ParentsPortal user={user} isParent={isParent} isMentor={isMentor} theme={theme} />
    ];
    
    if (isLoading || propsIsLoading) {
        return (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }} className={classes.loadingContainer}>
                <CircularProgress />
                <p>Loading dashboard...</p>
            </div>
        );
    }
    
    if (!user) {
        return (
            <div className={classes.loadingContainer}>
                <p>Error: User data not available. Please try refreshing the page.</p>
            </div>
        );
    }
    
    return (
        <div>
            <Grid container justifyContent="flex-end" className={classes.root} spacing={2}>
                <Grid item xs={9}>
                    <img
                        style={{
                            marginLeft: "10px",
                            width: "256px",
                            height: "76px"
                        }}
                        src="/icons/BridgePal-Stacked.svg"
                        alt={user.gender === "F" ? "XcBrgPalGirls" : "XcBrgPalBoys"}
                    />
                </Grid>
                
                <Grid item xs={1}>
                    <Badge
                        badgeContent={messageCount}
                        classes={{ badge: classes.notifBadge }}
                        color="primary"
                        overlap="rectangular"
                    >
                        {settings && settings?.chat_allowed === 1 ? (
                            <img
                                style={{
                                    width: "24px",
                                    height: "24px",
                                    paddingLeft: "76px",
                                    paddingTop: "20px",
                                    cursor: "pointer"
                                }}
                                onClick={() => history.push("/messenger")}
                                src="/icons/chat.png"
                                alt="Chat"
                            />
                        ) : null}
                    </Badge>
                </Grid>
                <Grid
                    item
                    className={classes.topNav}
                    xs={1}
                >
                    <BrAvatar
                        user={user}
                        theme={theme}
                        label={user.name}
                        onClick={() => history.push("/user/" + user.id)}
                        avatarStyles={{cursor: "pointer"}}
                    />
                </Grid>
                <Grid item xs={1}>
                    <SimpleMenu />
                </Grid>
                <NavTabs
                    user={user}
                    isParent={isParent}
                    isMentor={isMentor}
                    setValue={handleTabChange}
                    value={value}
                    panels={PANELS}
                    theme={theme}
                />
            </Grid>
            
            {PANELS[initialTab ? initialTab : value]}
        </div>
    );
};