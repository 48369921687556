import axios from "axios";
import { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
import Button from "../Input/Button";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

const { REACT_APP_API_URL } = process.env;

// Time slider marks
const TIME_MARKS = [
    { value: 1, label: "1h" },
    { value: 2, label: "2h" },
    { value: 4, label: "4h" },
    { value: 6, label: "6h" },
    { value: 8, label: "8h" }
];

const TIMEZONES = [
    {
        offset: "GMT-12:00",
        name: "Etc/GMT-12",
    },
    {
        offset: "GMT-11:00",
        name: "Etc/GMT-11",
    },
    {
        offset: "GMT-11:00",
        name: "Pacific/Midway",
    },
    {
        offset: "GMT-10:00",
        name: "America/Adak",
    },
    {
        offset: "GMT-09:00",
        name: "America/Anchorage",
    },
    {
        offset: "GMT-09:00",
        name: "Pacific/Gambier",
    },
    {
        offset: "GMT-08:00",
        name: "America/Dawson_Creek",
    },
    {
        offset: "GMT-08:00",
        name: "America/Ensenada",
    },
    {
        offset: "GMT-08:00",
        name: "America/Los_Angeles",
    },
    {
        offset: "GMT-07:00",
        name: "America/Chihuahua",
    },
    {
        offset: "GMT-07:00",
        name: "America/Denver",
    },
    {
        offset: "GMT-06:00",
        name: "America/Belize",
    },
    {
        offset: "GMT-06:00",
        name: "America/Cancun",
    },
    {
        offset: "GMT-06:00",
        name: "America/Chicago",
    },
    {
        offset: "GMT-06:00",
        name: "Chile/EasterIsland",
    },
    {
        offset: "GMT-05:00",
        name: "America/Bogota",
    },
    {
        offset: "GMT-05:00",
        name: "America/Havana",
    },
    {
        offset: "GMT-05:00",
        name: "America/New_York",
    },
    {
        offset: "GMT-04:30",
        name: "America/Caracas",
    },
    {
        offset: "GMT-04:00",
        name: "America/Campo_Grande",
    },
    {
        offset: "GMT-04:00",
        name: "America/Glace_Bay",
    },
    {
        offset: "GMT-04:00",
        name: "America/Goose_Bay",
    },
    {
        offset: "GMT-04:00",
        name: "America/Santiago",
    },
    {
        offset: "GMT-04:00",
        name: "America/La_Paz",
    },
    {
        offset: "GMT-03:00",
        name: "America/Argentina/Buenos_Aires",
    },
    {
        offset: "GMT-03:00",
        name: "America/Montevideo",
    },
    {
        offset: "GMT-03:00",
        name: "America/Araguaina",
    },
    {
        offset: "GMT-03:00",
        name: "America/Godthab",
    },
    {
        offset: "GMT-03:00",
        name: "America/Miquelon",
    },
    {
        offset: "GMT-03:00",
        name: "America/Sao_Paulo",
    },
    {
        offset: "GMT-03:30",
        name: "America/St_Johns",
    },
    {
        offset: "GMT-02:00",
        name: "America/Noronha",
    },
    {
        offset: "GMT-01:00",
        name: "Atlantic/Cape_Verde",
    },
    {
        offset: "GMT",
        name: "Europe/Belfast",
    },
    {
        offset: "GMT",
        name: "Africa/Abidjan",
    },
    {
        offset: "GMT",
        name: "Europe/Dublin",
    },
    {
        offset: "GMT",
        name: "Europe/Lisbon",
    },
    {
        offset: "GMT",
        name: "Europe/London",
    },
    {
        offset: "UTC",
        name: "UTC",
    },
    {
        offset: "GMT+01:00",
        name: "Africa/Algiers",
    },
    {
        offset: "GMT+01:00",
        name: "Africa/Windhoek",
    },
    {
        offset: "GMT+01:00",
        name: "Atlantic/Azores",
    },
    {
        offset: "GMT+01:00",
        name: "Atlantic/Stanley",
    },
    {
        offset: "GMT+01:00",
        name: "Europe/Amsterdam",
    },
    {
        offset: "GMT+01:00",
        name: "Europe/Belgrade",
    },
    {
        offset: "GMT+01:00",
        name: "Europe/Brussels",
    },
    {
        offset: "GMT+02:00",
        name: "Africa/Cairo",
    },
    {
        offset: "GMT+02:00",
        name: "Africa/Blantyre",
    },
    {
        offset: "GMT+02:00",
        name: "Asia/Beirut",
    },
    {
        offset: "GMT+02:00",
        name: "Asia/Damascus",
    },
    {
        offset: "GMT+02:00",
        name: "Asia/Gaza",
    },
    {
        offset: "GMT+02:00",
        name: "Asia/Jerusalem",
    },
    {
        offset: "GMT+03:00",
        name: "Africa/Addis_Ababa",
    },
    {
        offset: "GMT+03:00",
        name: "Asia/Riyadh89",
    },
    {
        offset: "GMT+03:00",
        name: "Europe/Minsk",
    },
    {
        offset: "GMT+03:30",
        name: "Asia/Tehran",
    },
    {
        offset: "GMT+04:00",
        name: "Asia/Dubai",
    },
    {
        offset: "GMT+04:00",
        name: "Asia/Yerevan",
    },
    {
        offset: "GMT+04:00",
        name: "Europe/Moscow",
    },
    {
        offset: "GMT+04:30",
        name: "Asia/Kabul",
    },
    {
        offset: "GMT+05:00",
        name: "Asia/Tashkent",
    },
    {
        offset: "GMT+05:30",
        name: "Asia/Kolkata",
    },
    {
        offset: "GMT+05:45",
        name: "Asia/Katmandu",
    },
    {
        offset: "GMT+06:00",
        name: "Asia/Dhaka",
    },
    {
        offset: "GMT+06:00",
        name: "Asia/Yekaterinburg",
    },
    {
        offset: "GMT+06:30",
        name: "Asia/Rangoon",
    },
    {
        offset: "GMT+07:00",
        name: "Asia/Bangkok",
    },
    {
        offset: "GMT+07:00",
        name: "Asia/Novosibirsk",
    },
    {
        offset: "GMT+08:00",
        name: "Etc/GMT+8",
    },
    {
        offset: "GMT+08:00",
        name: "Asia/Hong_Kong",
    },
    {
        offset: "GMT+08:00",
        name: "Asia/Krasnoyarsk",
    },
    {
        offset: "GMT+08:00",
        name: "Australia/Perth",
    },
    {
        offset: "GMT+08:45",
        name: "Australia/Eucla",
    },
    {
        offset: "GMT+09:00",
        name: "Asia/Irkutsk",
    },
    {
        offset: "GMT+09:00",
        name: "Asia/Seoul",
    },
    {
        offset: "GMT+09:00",
        name: "Asia/Tokyo",
    },
    {
        offset: "GMT+09:30",
        name: "Australia/Adelaide",
    },
    {
        offset: "GMT+09:30",
        name: "Australia/Darwin",
    },
    {
        offset: "GMT+09:30",
        name: "Pacific/Marquesas",
    },
    {
        offset: "GMT+10:00",
        name: "Etc/GMT+10",
    },
    {
        offset: "GMT+10:00",
        name: "Australia/Brisbane",
    },
    {
        offset: "GMT+10:00",
        name: "Australia/Hobart",
    },
    {
        offset: "GMT+10:00",
        name: "Asia/Yakutsk",
    },
    {
        offset: "GMT+10:30",
        name: "Australia/Lord_Howe",
    },
    {
        offset: "GMT+11:00",
        name: "Asia/Vladivostok",
    },
    {
        offset: "GMT+11:30",
        name: "Pacific/Norfolk",
    },
    {
        offset: "GMT+12:00",
        name: "Etc/GMT+12",
    },
    {
        offset: "GMT+12:00",
        name: "Asia/Anadyr",
    },
    {
        offset: "GMT+12:00",
        name: "Asia/Magadan",
    },
    {
        offset: "GMT+12:00",
        name: "Pacific/Auckland",
    },
    {
        offset: "GMT+12:45",
        name: "Pacific/Chatham",
    },
    {
        offset: "GMT+13:00",
        name: "Pacific/Tongatapu",
    },
    {
        offset: "GMT+14:00",
        name: "Pacific/Kiritimati",
    },
];

export default function TimeManagementModal({ open, handleClose, theme }) {
    const selectedChildId = window.localStorage.getItem('SELECTED_CHILD_ID');
    
    const useStyles = makeStyles((theme2) => ({
        button: {
            width: "80px",
            height: "25px",
            borderRadius: "12px",
            fontSize: "10px",
            margin: "5px",
        },
        root: {
            minHeight: 500,
            minWidth: 258,
        },
        bullet: {
            minHeight: "100px",
            margin: "0 2px",
            transform: "scale(0.8)",
        },
        title: {
            fontSize: 32,
            fontFamily: "GoodUnicorn",
            color: theme.primaryColor,
        },
        margin: {
            margin: theme2.spacing(1),
        },
        modal: {
            position: "absolute",
            width: 500,
            backgroundColor: theme2.palette.background.paper,
            border: "2px solid #000",
            boxShadow: theme2.shadows[5],
            padding: theme2.spacing(2, 4, 3),
            top: "20%",
            left: "30%",
            overflow: "scroll",
        },
        divider: {
            margin: 10,
        }
    }));
    
    const classes = useStyles();
    
    // Local state for form values
    const [settings, setSettings] = useState({
        dailyHours: 2,
        scheduleType: 'daily',
        startTime: '09:00',
        endTime: '17:00',
        timezone: 'UTC'
    });
    
    // Keep track of changes
    const [hasChanges, setHasChanges] = useState(false);
    
    // Load initial settings
    useEffect(() => {
        const fetchSettings = async () => {
            if (!open || !selectedChildId) return;
            
            try {
                const response = await axios.get(
                    `${REACT_APP_API_URL}/user_settings/${selectedChildId}`
                );
                
                if (response.data?.settings) {
                    setSettings({
                        dailyHours: response.data.settings.daily_hours || 2,
                        scheduleType: response.data.settings.schedule_type || 'daily',
                        startTime: response.data.settings.start_time || '09:00',
                        endTime: response.data.settings.end_time || '17:00',
                        timezone: response.data.settings.timezone || 'UTC'
                    });
                    setHasChanges(false);
                }
            } catch (error) {
                console.error('Failed to fetch settings:', error);
            }
        };
        
        fetchSettings();
    }, [open, selectedChildId]);
    
    // Save all changes
    const saveChanges = async () => {
        if (!hasChanges) {
            handleClose();
            return;
        }
        
        try {
            await axios.post(`${REACT_APP_API_URL}/user_settings/${selectedChildId}`, {
                daily_hours: settings.dailyHours,
                schedule_type: settings.scheduleType,
                start_time: settings.startTime,
                end_time: settings.endTime,
                timezone: settings.timezone
            });
            
            setHasChanges(false);
            handleClose();
        } catch (error) {
            console.error('Failed to save settings:', error);
        }
    };
    
    // Event handlers - only update local state
    const handleSliderChange = (_, value) => {
        setSettings(prev => ({ ...prev, dailyHours: value }));
        setHasChanges(true);
    };
    
    const handleScheduleChange = (event) => {
        const value = event.target.value;
        setSettings(prev => ({ ...prev, scheduleType: value }));
        setHasChanges(true);
    };
    
    const handleTimeChange = (type) => (event) => {
        const value = event.target.value;
        const isStartTime = type === 'startTime';
        
        // Validate time range
        if (isStartTime && value >= settings.endTime) return;
        if (!isStartTime && value <= settings.startTime) return;
        
        setSettings(prev => ({ ...prev, [type]: value }));
        setHasChanges(true);
    };
    
    const handleTimezoneChange = (_, value) => {
        if (!value) return;
        setSettings(prev => ({ ...prev, timezone: value }));
        setHasChanges(true);
    };
    
    // Handle modal close
    const handleModalClose = () => {
        if (hasChanges) {
            // Optional: Show confirmation dialog if there are unsaved changes
            if (window.confirm('You have unsaved changes. Save before closing?')) {
                saveChanges();
            } else {
                handleClose();
            }
        } else {
            handleClose();
        }
    };
    
    return (
        <Modal
            open={open}
            onClose={handleModalClose}
            aria-labelledby="time-management-modal"
        >
            <div className={classes.modal}>
                <Typography className={classes.title}>
                    Manage Time
                </Typography>
                
                <div className={classes.section}>
                    <Typography gutterBottom>Time Limit: {settings.dailyHours} hours</Typography>
                    <Slider
                        value={settings.dailyHours}
                        onChange={handleSliderChange}
                        min={1}
                        max={8}
                        step={1}
                        marks={TIME_MARKS}
                        style={{ color: theme.primaryColor }}
                    />
                </div>
                
                <Divider />
                
                <div className={classes.section}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Schedule Type</FormLabel>
                        <RadioGroup
                            value={settings.scheduleType}
                            onChange={handleScheduleChange}
                        >
                            <FormControlLabel
                                value="daily"
                                control={<Radio style={{ color: theme.primaryColor }} />}
                                label="Daily"
                            />
                            <FormControlLabel
                                value="weekday"
                                control={<Radio style={{ color: theme.primaryColor }} />}
                                label="Weekday"
                            />
                            <FormControlLabel
                                value="weekends"
                                control={<Radio style={{ color: theme.primaryColor }} />}
                                label="Weekends"
                            />
                        </RadioGroup>
                    </FormControl>
                </div>
                
                <Divider />
                
                <Grid container spacing={3} className={classes.section}>
                    <Grid item xs={6}>
                        <FormLabel>Start Time</FormLabel>
                        <TextField
                            type="time"
                            value={settings.startTime}
                            onChange={handleTimeChange('startTime')}
                            className={classes.timeField}
                            inputProps={{
                                step: 300 // 5 min
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormLabel>End Time</FormLabel>
                        <TextField
                            type="time"
                            value={settings.endTime}
                            onChange={handleTimeChange('endTime')}
                            className={classes.timeField}
                            inputProps={{
                                step: 300
                            }}
                        />
                    </Grid>
                </Grid>
                
                <FormControl fullWidth className={classes.timezoneField}>
                    <Autocomplete
                        options={TIMEZONES.map(tz => tz.name)}
                        value={settings.timezone}
                        onChange={handleTimezoneChange}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Timezone"
                                variant="outlined"
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "new-password",
                                }}
                            />
                        )}
                    />
                </FormControl>
                
                <div style={{ textAlign: "center", marginTop: "20px" }}>
                    <Button 
                        theme={theme} 
                        onClick={saveChanges}
                        style={{ 
                            opacity: hasChanges ? 1 : 0.7,
                            marginRight: '10px'
                        }}
                    >
                        {hasChanges ? 'Save Changes' : 'Done'}
                    </Button>
                    {hasChanges && (
                        <Button 
                            theme={theme} 
                            onClick={() => handleClose()}
                            style={{ opacity: 0.7 }}
                        >
                            Cancel
                        </Button>
                    )}
                </div>
            </div>
        </Modal>
    );
}