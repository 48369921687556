import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const PermissionToggle = ({ GreySwitch, checked, onChange, name }) => {
  const useStyles = makeStyles({
    toggleContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px'
    },
    stateLabel: {
      color: '#666',
      fontSize: '0.675rem',
      minWidth: '30px',
      fontWeight: 900
    }
  });

  const classes = useStyles();

  return (
    <div className={classes.toggleContainer}>
      <Typography className={classes.stateLabel}>
        {!checked ? 'Off' : ''}
      </Typography>
      <GreySwitch
        checked={checked}
        onChange={onChange}
        name={name}
      />
      <Typography className={classes.stateLabel}>
        {checked ? 'On' : ''}
      </Typography>
    </div>
  );
};

export default PermissionToggle;