import axios from "axios";
import { useEffect, useState, Fragment, useMemo, useCallback } from "react";
import BrAvatar from "./Avatar";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Chip from "@material-ui/core/Chip";
import { Link } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ProfileAccordions from "./ProfileAccordions";
import { usePusherConnection } from '../hooks/usePusherConnection';

const { REACT_APP_API_URL } = process.env;

export default function XCPals(props) {
    const { user, isParent, theme, showNotification } = props;
    
    const { isConnected, subscribeToPalNotifications } = usePusherConnection(user);
    
    const useStyles = makeStyles({
        button: {
            width: "80px",
            height: "25px",
            borderRadius: "12px",
            fontSize: "10px",
            margin: "5px"
        },
        root: {
            minHeight: 500,
            minWidth: 258
        },
        bullet: {
            minHeight: "100px",
            margin: "0 2px",
            transform: "scale(0.8)"
        },
        title: {
            fontSize: 32,
            fontFamily: "GoodUnicorn",
            color: theme.primaryColor
        },
        avatar: {
            marginLeft: "35%",
            marginTop: "10%",
            width: "20%",
            height: "20%"
        },
        palGrid: {
            minWidth: 300
        },
        palrow: {
            margin: 10
        },
        pos: {
            marginBottom: 12
        },
        chip1: {
            width: 110,
            fontSize: 12
        },
        chip2: {
            width: 120,
            fontSize: 12
        },
        toggle: {
            margin: "10%",
            borderRadius: "15px",
            backgroundColor: "#fffefe",
            textAlign: "center",
            whiteSpace: "nowrap"
        },
        xcIcon: {
            margin: 10
        },
        xcName: {
            fontSize: 12,
            margin: 5
        },
        rewardCard: {
            backgroundColor: "#efeaff"
        },
        hobbyCard: {
            backgroundColor: "#dbf2d1"
        },
        videosCard: {
            backgroundColor: "#ffe5e5"
        },
        imagesCard: {
            backgroundColor: "#d6f1fe"
        },
        subCardReward: {
            width: "50%",
            minHeight: "40px",
            marginTop: "20%",
            marginLeft: "10%",
            backgroundColor: "#937cff"
        },
        subCardHobbies: {
            width: "50%",
            minHeight: "40px",
            marginTop: "20%",
            marginLeft: "10%",
            backgroundColor: "#4bbe0d"
        },
        subCardVideos: {
            width: "50%",
            minHeight: "40px",
            marginTop: "20%",
            marginLeft: "10%",
            backgroundColor: "#fe787f"
        },
        subCardImages: {
            width: "50%",
            minHeight: "40px",
            marginTop: "20%",
            marginLeft: "10%",
            backgroundColor: "#2cbcf8",
        },
    });
    
    const classes = useStyles(theme);
    const [toggleFriends, setToggleFriends] = useState(0); // Start with requests tab
    const [selectedUser, setSelectedUser] = useState();
    const [friends, setFriends] = useState([]);
    const [pendingFriends, setPendingFriends] = useState([]);
    const [searchString, setSearchString] = useState("");
    
    // Modify refreshFriendData to handle parent-specific friend requests
    const refreshFriendData = useCallback(async () => {
        try {
            const targetId = isParent && user.children?.length > 0 
                ? window.localStorage.getItem('SELECTED_CHILD_ID') 
                : user.id;
            
            console.log('Refreshing friend data for:', {
                userId: targetId,
                isParent: isParent,
                toggleFriendsState: toggleFriends
            });
            
            if (toggleFriends === 0) {
                const result = await axios(`${REACT_APP_API_URL}/pending_friends/${targetId}`, {
                    headers: {
                        'Authorization': `Bearer ${window.localStorage.getItem('JWT_TOKEN')}`
                    }
                });
                console.log('Pending friends result:', result.data);
                setPendingFriends(result.data);
            } else {
                const result = await axios(`${REACT_APP_API_URL}/friends/${targetId}`, {
                    headers: {
                        'Authorization': `Bearer ${window.localStorage.getItem('JWT_TOKEN')}`
                    }
                });
                console.log('Friends result:', result.data);
                setFriends(result.data);
            }
        } catch (error) {
            console.error('Error refreshing friend data:', error);
            showNotification('Failed to fetch friend requests', 'error');
        }
    }, [toggleFriends, user, showNotification]);
    
    // Subscribe to friend request notifications
    useEffect(() => {
        if (isConnected && user?.id) {
            const cleanup = subscribeToPalNotifications((data, eventType) => {
                console.log(`${eventType} Notification received for user:`, {
                    eventName: eventType,
                    data,
                    userContext: {
                        id: user?.id,
                        isParent: isParent,
                        selectedChildId: window.localStorage.getItem('SELECTED_CHILD_ID')
                    }
                });
                
                if (isParent) {
                    // Show notification for parents
                    showNotification(
                        `New friend request for your child from ${data.friend_name || 'someone'}`,
                        'info'
                    );
                }
                
                // Force refresh of data
                setPendingFriends([]); // Clear first to force re-render
                refreshFriendData();
            });
            
            // Initial data fetch
            refreshFriendData();
            
            return () => {
                if (cleanup) cleanup();
            };
        }
    }, [isConnected, user, refreshFriendData, showNotification]);
    
    useEffect(() => {
        refreshFriendData();
    }, [toggleFriends, refreshFriendData]);
    
    useEffect(() => {
        setToggleFriends(0);
    }, []);
    
    const filterFriends = (e) => {
        setSearchString(e.target.value);
    };
    
    // TODO: Refactor this function -- remove unnecessary logic
    const confirmFriend = async (userId) => {
        try {
            const targetId = isParent && user.children?.length > 0 
            ? window.localStorage.getItem('SELECTED_CHILD_ID') 
            : user.id;
            
            const result = await axios(
                `${REACT_APP_API_URL}/friends/approve/${userId}/${targetId}`,
                { 
                    method: "POST",
                    headers: {
                        'Authorization': `Bearer ${window.localStorage.getItem('JWT_TOKEN')}`
                    }
                }
            );
            if (result.status === 200) {
                showNotification("Friendship confirmed!", "success");
                setPendingFriends(prevFriends => 
                    prevFriends.filter(friend => friend.id !== userId)
                );
            } else {
                console.error('Error confirming friend:', result.data);
                showNotification("Error confirming friend", "error");
            }
        } catch (error) {
            console.error('Error confirming friend:', error);
            showNotification("Error confirming friend", "error");
        }
    };
    
    const removeFriend = async (userId) => {
        try {
            const targetId = isParent && user.children?.length > 0 
            ? window.localStorage.getItem('SELECTED_CHILD_ID') 
            : user.id;
            
            const result = await axios(
            `${REACT_APP_API_URL}/friends/${userId}/${targetId}`,
                { 
                    method: "DELETE",
                    headers: {
                        'Authorization': `Bearer ${window.localStorage.getItem('JWT_TOKEN')}`
                    }
                }
            );
            if (result.status === 200) {
                console.log('Friend removed:', result.data);
                showNotification("Friend removed", "success");
                setPendingFriends(prevFriends => 
                    prevFriends.filter(friend => friend.id !== userId)
                );
            }
            else {
                console.error('Error removing friend:', result.data);
                showNotification("Error removing friend", "error");
            }
        } catch (error) {
            console.error('Error removing friend:', error);
            showNotification("Error removing friend", "error");
        }
    };
    
    
    const XCPalRow = useMemo(
        () => (props) => {
            const {
                pal,
                user,
                isParent,
                selectedUser,
                classes,
                confirmFriend,
                removeFriend,
                toggleFriends,
                style,
            } = props;
            console.log("🚀 ~ XCPals ~ isParent: ", isParent);
            console.log("🚀 ~ XCPals ~ toggleFriends: ", toggleFriends);
            
            // Determine if confirm button should be shown
            const showConfirmButton = isParent && toggleFriends === 0;
            
            // Determine if child user is allowed to chat
            const chatAllowed = user.settings.chat_allowed === 1;
            
            return (
                <Paper
                    elevation={0}
                    style={
                        selectedUser && selectedUser.id === pal.id
                        ? { backgroundColor: "#f1f1f1", ...style }
                        : { ...style }
                    }
                    className={classes.palrow}
                    onClick={() => setSelectedUser(pal)}
                >
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <BrAvatar user={pal} theme={theme} className={classes.xcIcon} />
                    </Grid>
                    <Grid item xs={9}>
                        <Typography className={classes.xcName}> {pal.name} </Typography>
                        {toggleFriends === 0 ? (
                            <Fragment>
                                {showConfirmButton && (
                                    <Button
                                        theme={theme}
                                        className={classes.button}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            confirmFriend(pal.id);
                                        }}
                                        variant="contained"
                                    >
                                        Confirm
                                    </Button>
                                )}
                                <Button
                                    theme={theme}
                                    className={classes.button}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        removeFriend(pal.id);
                                    }}
                                    variant="contained"
                                >
                                    Delete
                                </Button>
                            </Fragment>
                        )  : (
                    <Link
                        style={{ textDecoration: "none" }}
                        to={`/messenger/${pal.id}#` + pal.id}
                    >
                        {user.name !== "parent" && chatAllowed ? <Button
                            theme={theme}
                            className={classes.button}
                            variant="contained"
                        >
                            {" "}
                            Chat{" "}
                        </Button> : null}
                    </Link>
                )}
                    </Grid>
                </Grid>
                </Paper>
            );
        },
        [setSelectedUser, theme, confirmFriend, removeFriend]
    );
    
    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={1} />
                <Grid className={classes.title} item xs={11}>
                    My XC Pals
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={2} className={classes.palGrid}>
                <Card className={classes.root}>
                    <Paper className={classes.toggle}>
                        <Chip
                            onClick={() => setToggleFriends(1)}
                            className={classes.chip1}
                            style={
                            toggleFriends === 1
                                ? {
                                    zIndex: 1,
                                    color: theme.primaryColor,
                                    backgroundColor: theme.secondaryColor,
                                }
                                : { backgroundColor: "#fffefe" }
                            }
                            label="My XC Pals"
                        />
                        <Chip
                            onClick={() => setToggleFriends(0)}
                            className={classes.chip2}
                            style={
                            toggleFriends === 0
                                ? {
                                    zIndex: 1,
                                    color: theme.primaryColor,
                                    backgroundColor: theme.secondaryColor,
                                }
                                : { backgroundColor: "#fffefe" }
                            }
                            label="XC Pal Requests"
                        />
                    </Paper>
                    <Paper
                        elevation={5}
                        style={{ width: "95%", textAlign: "center", marginLeft: "10px" }}
                    >
                        <TextField
                            style={{ width: "100%", height: "30px" }}
                            variant={"filled"}
                            placeholder={"Search"}
                            onChange={filterFriends}
                            value={searchString}
                            InputLabelProps={{
                                style: { height: "30px" },
                                shrink: "true"
                            }}
                            InputProps={{
                                style: {
                                    height: "35px",
                                    backgroundColor: "white",
                                    padding: 0,
                                    after: {
                                        content: "none",
                                    },
                                    before: { content: "none" }
                                },
                                shrink: "true",
                                disableUnderline: true,
                            }}
                            inputProps={{
                                style: { paddingTop: "10px", paddingBottom: "0px" },
                            }}
                        />
                    </Paper>
                    <div style={{ padding: "10px" }}></div>
                    
                    {toggleFriends
                        ? friends.map((pal) => {
                            return searchString === "" ||
                                pal.name.toUpperCase().includes(searchString.toUpperCase()) ? (
                                    <XCPalRow
                                        key={pal.id}
                                        user={user}
                                        isParent={isParent}
                                        pal={pal}
                                        selectedUser={selectedUser}
                                        classes={classes}
                                        confirmFriend={confirmFriend}
                                        removeFriend={removeFriend}
                                        toggleFriends={toggleFriends}
                                    />
                                    ) : (
                                        <div
                                            style={{ display: "none" }}
                                        />
                                    );
                                })
                        : pendingFriends.map((pal) => {
                            return searchString === "" ||
                                pal.name.toUpperCase().includes(searchString.toUpperCase()) ? (
                                <XCPalRow
                                    key={pal.id}
                                    user={user}
                                    isParent={isParent}
                                    pal={pal}
                                    selectedUser={selectedUser}
                                    classes={classes}
                                    confirmFriend={confirmFriend}
                                    removeFriend={removeFriend}
                                    toggleFriends={toggleFriends}
                                />
                                ) : (
                                    <XCPalRow
                                        user={pal}
                                        isParent={isParent}
                                        style={{ display: "none" }}
                                        selectedUser={selectedUser}
                                        classes={classes}
                                        confirmFriend={confirmFriend}
                                        removeFriend={removeFriend}
                                        toggleFriends={toggleFriends}
                                    />  
                                );
                            })}
                    </Card>
                </Grid>
                <Grid item xs={6}>
                    <Card raised className={classes.root}>
                        {selectedUser ? (
                            <Fragment>
                                <div style={{ width: "100%", textAlign: "center" }}>
                                    <BrAvatar
                                        avatarStyles={{ width: "50%", height: "50%" }}
                                        user={selectedUser}
                                        theme={theme}
                                        className={classes.avatar}
                                    />
                                    <Typography>{selectedUser.name}</Typography>
                                </div>
                                <ProfileAccordions
                                    userId={selectedUser.id}
                                    user={selectedUser}
                                />
                            </Fragment>
                        ) : (
                        ""
                        )}
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
}