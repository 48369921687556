import React, { useRef, useState, useEffect, useMemo } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Badge from "@material-ui/core/Badge";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { TextField } from "@material-ui/core";


export default function Messenger(props) {
    const {
        characterCount,
        messages,
        message,
        selectedUser,
        sendMessage,
        handleMessageInput,
        handleRemove,
        handleEdit,
        edit,
        user,
        theme,
        isParent
    } = props;
    
    const [uiReady, setUiReady] = useState(false);
    const conversationRef = useRef(null);
    
    useEffect(() => {
        // Delay UI initialization slightly to ensure hydration is complete
        const initTimer = setTimeout(() => {
            setUiReady(true);
        }, 100);
        
        return () => clearTimeout(initTimer);
    }, []);
    
    useEffect(() => { 
        if (conversationRef.current) { 
            conversationRef.current.scrollTop = conversationRef.current.scrollHeight; 
        } 
    }, [messages]);
    
    
    const useStyles = makeStyles({
        root: {
            minHeight: 500,
            maxHeight: 500,
        },
        bullet: {
            minHeight: "100px",
            margin: "0 2px",
            transform: "scale(0.8)",
        },
        conversation: {
            width: "100%",
            overflow: "scroll",
            height: "332px",
            paddingInlineStart: 0,
        },
        title: {
            fontSize: 32,
            fontFamily: "GoodUnicorn",
            color: "#eb4c8a",
        },
        avatar: {
            marginLeft: "30%",
            marginTop: "40%",
            width: "20%",
            height: "20%",
        },
        pos: {
            marginBottom: 12,
        },
        heading: {
            fontSize: 16,
            flexBasis: "33.33%",
            flexShrink: 0,
        },
        chatGrid: {
            marginLeft: "40px",
        },
        playIcon: {
            color: "#eb4c8a",
            cursor: "pointer",
            position: "absolute",
            top: "20%",
            left: "25%",
            transform: "translate(-25%, -25%)",
        },
        imagesCard: {
            backgroundColor: "#d6f1fe",
        },
        images: {
            margin: "4px",
        },
        toggle: {
            margin: "10%",
            borderRadius: "15px",
            backgroundColor: "#fffefe",
            textAlign: "center",
        },
        self: {
            justifyContent: "flex-end",
            alignItems: "flex-end",
        },
        selfChip: {
            background: `linear-gradient(90deg, ${theme.secondaryColor} 20%, ${theme.primaryColor} 80%)`,
            color: "white",
        },
        editChip: {
            background: `${theme.primaryColor}`,
            color: "white",
        },
        recipientChip: {
            background: "floralwhite",
        },
        messengerInputContainer: {
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            minHeight: '56px',
            backgroundColor: '#fff',
            borderTop: '1px solid rgba(0, 0, 0, 0.12)',
            display: 'flex',
            alignItems: 'center',
            padding: '8px',
        }
    });
    
    // Check if user should have input access
    const canShowInput = useMemo(() => {
        if (!user || !user.roles) return false;
        return user.roles.some(role => role.name === "child") || 
                (!user.roles.some(role => role.name === "parent"));
    }, [user]);
    
    const classes = useStyles();
    
    const chipCustom = makeStyles({
        label: { whiteSpace: "normal", overflow: "auto", margin: "5px" },
    })();
    
    const SimpleMenu = (props) => {
        const [anchorEl, setAnchorEl] = React.useState(null);
        const { message } = props;
        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };
        
        const handleClose = () => {
            setAnchorEl(null);
        };
        
        return (
            <Badge
                classes={{ badge: classes.badge }}
                color="primary"
                overlap="rectangular">
                <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    style={{ color: theme.primaryColor }}
                    onClick={handleClick}
                >
                <MoreVertIcon />
                </IconButton>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem
                        onClick={() => handleEdit(message)}
                    >
                    Edit
                    </MenuItem>
                    <MenuItem
                        onClick={() => handleRemove(message)}
                    >
                    Delete
                    </MenuItem>
                </Menu>
            </Badge>
        );
    };
    
    const formatMessageDate = (dateString) => {
        if (!dateString) return '';
        
        const date = new Date(dateString);
        if (isNaN(date.getTime())) return ''; // Handle invalid date
        
        // Use a more robust date formatting
        return new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        }).format(date);
    };
    
    return (
        <Paper
            elevation={1}
            className={classes.root}
            style={{ minWidth: 700, position: "absolute" }}
        >
            <div
            style={{
                textAlign: "center",
                height: "45px",
                backgroundColor: "rgb(241,241,241)",
            }}
            >
                <div style={{ padding: "10px", margin: "auto" }}>
                    {" "}
                    Chat with {selectedUser.name}{" "}
                </div>
            </div>
            
            <Grid container>
                <ol className={classes.conversation} ref={conversationRef}>
                    {messages.map((message) => {
                        let chipClass ='';
                        if(message.sender_id !== user.id) {
                            chipClass = classes.recipientChip;
                        } else if(edit.id === message.id ){
                            chipClass = classes.editChip
                        } else {
                            chipClass = classes.selfChip;
                        }
                        
                        return (
                            <li
                                key={message.id * Math.random()} style={{ display: "flex" }}
                                className={message.sender_id === user.id ? classes.self : ""}
                            >
                            <img key={message.id * Math.random()} className="avatar" />
                            
                            <div key={message.id * Math.random()} className="message">
                            <div style={{marginLeft:'0px'}}>
                                {formatMessageDate(message.created_at)}
                            </div>
                                <Chip
                                    className={chipClass}
                                    style={{
                                        margin: "5px",
                                        maxWidth: "250px",
                                        maxHeight: "150px",
                                        minHeight: "32px",
                                        height: "auto",
                                        paddingBottom: "20px",
                                    }}
                                    label={message.content}
                                    classes={{ label: chipCustom.label }}
                                />
                                {!isParent && message.sender_id === user.id ? <SimpleMenu message={message} /> : null }
                            </div>
                            </li>
                        );
                    })}
                </ol>
            </Grid>
            {uiReady && canShowInput && (
                <div className="messengerInputContainer">
                <TextField
                    style={{
                        position: "absolute",
                        bottom: "0",
                        left: "0",
                        width: "95%",
                    }}
                    multiline
                    value={message}
                    onChange={(e) => handleMessageInput(e)}
                    onKeyDown={(e) => handleMessageInput(e)}
                    placeholder="Write your message here..."
                    variant="filled"
                />
                <Typography> {characterCount}/240</Typography>
                <IconButton
                    style={{
                        position: "absolute",
                        bottom: "5px",
                        right: "5px",
                        maxHeight: "32px",
                        maxWidth: "32px",
                    }}
                    onClick={sendMessage}
                    disabled={!message.trim()}
                >
                    <img
                        style={{
                            maxHeight: "24px",
                            maxWidth: "24px",
                            marginBottom: "5px",
                        }}
                        src="/icons/001-paper-plane.png"
                        alt="Send"
                    />
                </IconButton>
            </div>
            )}
        </Paper>
    );
};