import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";


function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: "80%",
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    buttonBrg: {
        background: "transparent",
        fontSize: 22,
        fontFamily: 'GoodUnicorn',
        marginTop: 10,
        fontWeight: 600,
        border: "none",
        boxShadow: "none",
    },
    buttonModal: {
        background: "#ff9b45",
        fontSize: 22,
        fontFamily: 'GoodUnicorn',
        marginTop: 10,
        fontWeight: 600,
        color: "#FFF",
        border: "none",
        boxShadow: "none",
        width: "50%",
        "&:hover": {
            textDecoration: "auto",
            backgroundColor: "#ff9b45",
            opacity: 0.9,
        }
    },
    text: {
        padding: "20px",
        margin: "25px 0px",
    }
}));

export default function WarningBeforeLeavingWebsiteButtonAndModal(props) {
    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);
    const [open, setOpen] = React.useState(false);
    const { location, user, buttonText } = props;

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const body = (
        <div style={modalStyle} className={classes.paper}>
            <Typography id="modal-modal-title" variant="h6" component="h2" style={{marginTop: 10}}>
                <Alert severity="warning">Warning</Alert>
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }} className={classes.text}>
                You are now leaving the XcBrgPal Web-App Platform. XcBrgPal offers links to other
                third-party websites that may be of interest and beneficial to our web-app users.
                When you click on these links you will leave the XcBrgPal Platform and will be
                redirected to another site. These sites are not under the control of XcBrgPal.
                <br /> <br />
                XcBrgPal is not responsible for the content of linked third party websites. Please be
                aware that the security and privacy policies on these sites may be different than
                XcBrgPal policies, so please read third party privacy and security policies closely.
                When you use a third-party site, you will be subject to its terms and licenses and no
                longer be protected by XcBrgPal privacy policy or security practices.
                <br /> <br />
                If you have any questions or concerns about the products and services offered on
                linked third party websites, please contact the third party directly.
                <br /> <br />
                By clicking on the CONTINUE button below, you accept the above statement and will
                be taken to the linked site. If you want to remain on the XcBrgPal Platform, select
                the CANCEL button
            </Typography>
            <Grid container spacing={2} style={{marginTop: 20}}>
                <Grid container justifyContent="center" spacing={3}>
                    <Button
                        className={classes.buttonModal}
                        onClick={(e) => {
                            window.open(location, '_blank');
                            handleClose();
                        }}
                    >
                        {" "}
                        Continue
                        {" "}
                    </Button>
                </Grid>
                <Grid container justifyContent="center" spacing={3}>
                    <Button
                        className={classes.buttonModal}
                        onClick={(e) => {
                            handleClose()
                        }}
                    >
                        {" "}
                        Cancel
                        {" "}
                    </Button>
                </Grid>
            </Grid>
        </div>
    );

    return (
        <div>
            <Button
                className={classes.buttonBrg}
                style={user.gender === "M" ? {
                        color: "#3344cc",
                    } : {
                        color: "#eb4c8a"
                    }}
                onClick={handleOpen}>
            {buttonText}
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {body}
            </Modal>
        </div>
    );
}
