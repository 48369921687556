import axios from "axios";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

const { NODE_ENV, REACT_APP_API_URL } = process.env;

function Logout() {
    const history = useHistory();
    
    useEffect(async () => {
        let token = window.localStorage.getItem("JWT_TOKEN");
        
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        
        const result = await axios(
            `${REACT_APP_API_URL}/auth/logout`
        ).then((result) => {
            window.localStorage.removeItem("JWT_TOKEN");
            window.localStorage.removeItem("USER");
            window.localStorage.removeItem("CHILDREN");
            window.localStorage.removeItem("SETTINGS");
            window.localStorage.removeItem("SELECTED_CHILD_ID")
            
            if (NODE_ENV === "development") {
                window.location.href = "http://localhost:3000/";
            } else if (NODE_ENV === "staging") {
                window.location.href = "https://staging.bridgepalkids.com/";
            } else {
                window.location.href = "https://www.bridgepalkids.com/"; // Redirect user back to the Wix website.
            }
        });
        
        // If the axios request fails, redirect the user back to the Wix website.
        if (result.status !== 200) {
            console.error("Logout failed!");
            console.error(`Logout::Logout() failed! Status: ${result.status} - ${result.statusText}`);
            window.location.href = "https://www.bridgepalkids.com/";
        }
    }, []);
    
    return <div />;
}

export default Logout;
