import axios from "axios";
import { useEffect, useState, useCallback, useMemo } from "react";
import Routes from "./Routes";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { refreshToken } from "./authUtils";
import { PusherProvider } from "./PusherProvider";
import { PusherManager } from "./PusherManager";
import "./App.css";

const { REACT_APP_API_URL } = process.env;

axios.interceptors.response.use(
(response) => response,
    async (error) => {
        const originalRequest = error.config;
        const isPusherRequest = originalRequest.url.includes('/broadcasting/auth');
        const isRefreshRequest = originalRequest.url.includes('auth/refresh');
        
        // Don't retry Pusher auth requests or refresh requests
        if (error.response?.status === 401 && !originalRequest._retry 
            && !isPusherRequest && !isRefreshRequest) {
                originalRequest._retry = true;
                
                try {
                    const newToken = await refreshToken();
                    if (newToken) {
                    // Update tokens everywhere
                    axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
                    originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
                    
                    // If Pusher is connected, disconnect and reconnect with new token
                    if (window.Echo) {
                        window.Echo.disconnect();
                        window.Echo = null;
                        // The PusherManager will automatically reconnect
                    }
                    
                    return axios(originalRequest);
                }
                
                // Handle failed refresh
                window.localStorage.clear();
                console.error(`Failed to refresh token! Setting window.location.href to /`);
                window.location.href = '/';
                return Promise.reject(error);
            } catch (refreshError) {
                window.localStorage.clear();
                console.error(`Failed to refresh token! Setting window.location.href to /`);
                window.location.href = '/';
                return Promise.reject(refreshError);
            }
        }
        
        return Promise.reject(error);
    }
);

// Main App component
function App() {
    const { user, setUser, isParent, isMentor, kids, selectChild, setSelectChild, isDataFetched } = useAuth();
    console.log("🚀 ~ file: App.jsx ~ App ~ TOP LEVEL PROP OBJECT ~ user:", {user});
    const { open, message, severity, showNotification, hideNotification } = useNotification();
    
    // State for Pusher
    const [isPusherInitialized, setIsPusherInitialized] = useState(false);
    
    const handlePusherConnectionChange = useCallback((status, error) => {
    // console.log('PusherConnectionChange:', status, error); // Debug log
    
    if (status === 'error') {
        // Check if user is a mentor and if error can be safely ignored
        const isMentor = user?.roles?.some(role => role.name === "mentor");
        
        if (isMentor && (error?.response?.status === 500 || error?.toString().includes('authorization'))) {
            console.log('Suppressing Pusher error for mentor user - continuing without real-time updates');
            setIsPusherInitialized(true); // Allow the app to continue
        } else {
            console.error('Pusher connection error:', error);
            showNotification('Lost connection to real-time updates', 'error');
        }
    } else if (status === 'connected') {
        console.log('Setting isPusherInitialized to true'); // Debug log
        setIsPusherInitialized(true);
        console.log('Successfully connected to Pusher!');
    }
    }, [showNotification]);
    
    // Memoized theme to prevent unnecessary re-renders
    const theme = useMemo(() => {
        return user?.gender === "M"
            ? {
                primaryColor: "#3344cc",
                secondaryColor: "#ababdc",
                barColor: "hsl(29deg 91% 79%)",
                backgroundLogo: "/images/m-racing.jpg"
            }
            : {
                primaryColor: "#eb4c8a",
                secondaryColor: "#fccfdf",
                barColor: "#fcedf3",
                backgroundLogo: "/images/f-meadow.jpg"
            };
    }, [user?.gender]);
    
    // Callback for selecting a child user
    const proceedWithChild = useCallback((selectedUser) => {
        setSelectChild(false);
        setUser({ ...selectedUser, isParent  });
    }, [isParent, setUser]);
    
    // Only show loading state if we have a user but Pusher isn't initialized yet
    if (user && !isPusherInitialized) {
        console.log('Showing initialization state'); // Debug log
        return (
            <PusherProvider user={user}>
                <PusherManager 
                    user={user} 
                    onConnectionChange={handlePusherConnectionChange} 
                />
                <div>Initializing Pusher connection...</div>
            </PusherProvider>
        );
    }
    
    return (
        <>
            <PusherProvider user={user}>
                <PusherManager user={user} onConnectionChange={handlePusherConnectionChange} />
                <Routes
                    user={user}
                    isParent={isParent}
                    isMentor={isMentor}
                    kids={kids}
                    setUser={setUser}
                    selectChild={selectChild}
                    proceedWithChild={proceedWithChild}
                    theme={theme}
                    showNotification={showNotification}
                    isDataFetched={isDataFetched}
                />
                <Snackbar open={open} autoHideDuration={6000} onClose={hideNotification}>
                    <MuiAlert elevation={6} variant="filled" onClose={hideNotification} severity={severity}>
                        {message}
                    </MuiAlert>
                </Snackbar>
            </PusherProvider>
        </>
    );
};

// TODO: Move this to a separate file and import it
// Custom hook for managing authentication state
function useAuth() {
    const [user, setUser] = useState(null);
    const [isParent, setIsParent] = useState(false);
    const [isMentor, setIsMentor] = useState(false);
    const [kids, setKids] = useState(null);
    const [selectChild, setSelectChild] = useState(false);
    const [isDataFetched, setIsDataFetched] = useState(false);
    
    const fetchUser = useCallback(async () => {
        try {
            const urlParams = new URLSearchParams(window.location.search);
            const urlToken = urlParams.get('token');
            const storedToken = window.localStorage.getItem('JWT_TOKEN');
            
            // If no token available, don't proceed
            if (!storedToken && !urlToken) {
                setIsDataFetched(true);
                return;
            }
            
            const token = urlToken || storedToken;
            
            // Set token in localStorage and axios headers
            window.localStorage.setItem('JWT_TOKEN', token);
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            
            // If token is valid, fetch user data
            const response = await axios.get(`${REACT_APP_API_URL}/auth/me`);
            const userData = response.data;
            
            if (!userData) throw new Error('No user data received');
            
            // Process user data and update state
            processUserData(userData);
        } catch (error) {
            console.error('Auth error:', error);
            // Clear storage only if it's an auth error
            if (error.response?.status === 401) {
                window.localStorage.clear();
            };
            setUser(null);
        } finally {
            setIsDataFetched(true);
        }
    }, []);
    
    // Extract user data processing logic
    const processUserData = useCallback((userData) => {
        const { token, children } = userData;
        
        const isParent = userData.name === "parent" || children?.length > 0 || (userData.roles?.find(role => role.id === 2))?.length > 0;
        const isMentor = (userData.roles?.find(role => role.id === 3))?.length > 0 || userData.name === "mentor";
        
        
        if (isParent && !isMentor) {
            setIsParent(isParent);
            setKids(children?.length > 0 ? children : null);
            
            // Check if we have a previously selected child in local storage
            const selectedChildId = window.localStorage.getItem('SELECTED_CHILD_ID');
            
            // If a child was previously selected, use it
            if (selectedChildId) {
                const selectedChild = children.find(child => child.id === parseInt(selectedChildId));
                if (selectedChild) {
                    setUser({ ...userData, isParent, selectedChild });
                    setSelectChild(false);
                    return;
                }
            }
            
            // Only show child selection if no child was previously selected
            setSelectChild(true);
            setUser({ ...userData, access_token: token, isParent });
        } else if (userData.roles?.find(role => role.id === 3)) {
            setIsMentor(isMentor);
            setUser({ ...userData, access_token: token, isMentor });
        } else {
            setUser({ ...userData, access_token: token, isKid: true });
        }
        
        // Update local storage
        window.localStorage.setItem("USER", JSON.stringify(userData));
        window.localStorage.setItem("CHILDREN", JSON.stringify(children));
        window.localStorage.setItem("SETTINGS", JSON.stringify(userData.settings));
    }, []);
    
    useEffect(() => {
        fetchUser();
    }, [fetchUser]);
    
    return { user, setUser, isParent, isMentor, kids, selectChild, setSelectChild, isDataFetched };
}

// Custom hook for notifications
function useNotification() {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("info");
    
    const showNotification = useCallback((message, severity = "info") => {
        setMessage(message);
        setSeverity(severity);
        setOpen(true);
    }, []);
    
    const hideNotification = useCallback((event, reason) => {
        if (reason === "clickaway") return;
        setOpen(false);
    }, []);
    
    return { open, message, severity, showNotification, hideNotification };
};


export default App;