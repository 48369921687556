import { useState, useEffect, useCallback, useRef } from 'react';

export const usePusherConnection = (user) => {
    const [isConnected, setIsConnected] = useState(false);
    const checkConnectionRef = useRef(null);
    
    // Check connection status
    useEffect(() => {
        if (checkConnectionRef.current) {
            clearInterval(checkConnectionRef.current);
        }
        
        checkConnectionRef.current = setInterval(() => {
            if (window.Echo?.connector?.pusher?.connection?.state === 'connected') {
                setIsConnected(true);
                clearInterval(checkConnectionRef.current);
            }
        }, 100);
        
        return () => {
            if (checkConnectionRef.current) {
                clearInterval(checkConnectionRef.current);
            }
        };
    }, []);
    
    // Memoize subscribeToChannel to prevent unnecessary recreations
    const subscribeToChannel = useCallback((channelName, eventTypes, handler) => {
        if (!isConnected || !window.Echo || !user?.id) return null;
        
        try {
            const channel = window.Echo.private(channelName);
            
            eventTypes.forEach(eventType => {
                channel.listen(eventType, (data) => {
                    if (user.name === "parent") {
                        const selectedChildId = window.localStorage.getItem('SELECTED_CHILD_ID');
                        const isRelevantNotification = 
                            data.child_id === selectedChildId ||
                            data.recipient_id === selectedChildId ||
                            data.to_id === selectedChildId;
                        
                        if (isRelevantNotification) {
                            handler(data, eventType);
                        }
                    } else {
                        handler(data, eventType);
                    }
                });
            });
            
            return () => {
                eventTypes.forEach(eventType => {
                    try {
                        channel.stopListening(eventType);
                    } catch (error) {
                        console.error(`Error cleaning up event ${eventType}:`, error);
                    }
                });
            };
        } catch (error) {
            console.error(`Error subscribing to channel ${channelName}:`, error);
            return null;
        }
    }, [isConnected, user?.id]); // Only depend on connection status and user ID
    
    // Memoize notification subscriptions
    const subscribeToPalNotifications = useCallback((handler) => {
        if (!user?.id) return null;
        
        // Determine appropriate channel based on user role
        let channelName;
        if (user.name === "parent") {
            channelName = `notifications.${user.id}`;
        } else if (user.roles?.some(role => role.name === "mentor")) {
            // Use a mentor-specific channel or the notifications channel
            channelName = `notifications.${user.id}`;
        } else {
            channelName = `friend-requests.${user.id}`;
        }
        
        console.log(`Subscribing to channel: ${channelName}`);
        
        return subscribeToChannel(
            channelName,
            ['notification', 'SentNotification'],
            handler
        );
    }, [subscribeToChannel, user]);
    
    const subscribeToMenuNotifications = useCallback((handler) => {
        if (!user?.id || user?.name !== "parent") return null;
        
        return subscribeToChannel(
            `notifications.${user.id}`,
            ['notification'],
            handler
        );
    }, [subscribeToChannel, user?.id, user?.name]);
    
    return {
        isConnected,
        subscribeToPalNotifications,
        subscribeToMenuNotifications
    };
};