import { useEffect, useState } from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import Button from "../Input/Button";
import Paper from "@material-ui/core/Paper";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import NavTabs from "../Dashboard/NavTabs";
import Header from "./Header";

const { REACT_APP_API_URL } = process.env;

export default function Hobbies(props) {
    const { user, theme, showNotification } = props;
    const [value, setValue] = useState(6);
    const [messageCount, setMessageCount] = useState(0) // TODO: Echo integration.
    
    const useStyles = makeStyles({
        root: {
            minHeight: '100vh',
            width: '100%',
            backgroundImage: props => `url(${props.theme.backgroundLogo})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
            backgroundAttachment: 'fixed',
            position: 'relative',
            '&::before': {
                content: '""',
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                backgroundColor: 'rgba(255, 255, 255, 0.6)',
                zIndex: 1,
            },
        },
        content: {
            position: 'relative',
            zIndex: 2,
            padding: '20px',
        },
        title: {
            fontSize: 32,
            fontFamily: "GoodUnicorn",
            color: theme.primaryColor,
            textAlign: 'center',
            marginBottom: '20px',
        },
        paper: {
            padding: '20px',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
        },
        hobbyGrid: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
        },
        hobbyCard: {
            width: '100px',
            height: '100px',
            margin: '10px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            transition: 'all 0.3s ease',
            '&:hover': {
                transform: 'scale(1.05)',
            },
        },
        selectedHobby: {
            border: `2px solid ${theme.primaryColor}`,
            boxShadow: '0 0 10px rgba(0,0,0,0.2)',
        },
        hobbyImage: {
            width: '60%',
            height: '60%',
            objectFit: 'contain',
        },
        hobbyName: {
            marginTop: '5px',
            fontSize: '12px',
            textAlign: 'center',
        },
        saveButtonContainer: {
            display: 'flex',
            justifyContent: 'center',
            marginTop: '20px',
        },
    });
    
    const classes = useStyles({ theme });
    
    const [hobbies, setHobbies] = useState([]);
    const [open, setOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [severity, setSeverity] = useState("");
    
    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    };
    
    useEffect(() => {
        const fetchHobbies = async () => {
            const hobbies = await axios(`${REACT_APP_API_URL}/hobbies`);
            const userHobbies = await axios(`${REACT_APP_API_URL}/hobbies/${user.id}`);
            const updatedHobbies = hobbies.data.map((hobby) => ({
                ...hobby,
                selected: userHobbies.data.some(userHobby => userHobby.id === hobby.id)
            }));
            setHobbies(updatedHobbies);
        };
        fetchHobbies();
    }, [user.id]);
    
    const toggleHobby = (index) => {
        setHobbies(hobbies.map((hobby, i) => 
            i === index ? { ...hobby, selected: !hobby.selected } : hobby
        ));
    };
    
    const saveHobbies = async () => {
        const selectedHobbies = hobbies.filter((hobby) => hobby.selected);
        try {
            await axios.post(`${REACT_APP_API_URL}/hobbies/${user.id}`, { hobbies: selectedHobbies });
            setSeverity("success");
            setErrorMessage("Successfully updated hobbies!");
            setOpen(true);
        } catch (e) {
            setSeverity("error");
            setErrorMessage("Unable to update hobbies.");
            setOpen(true);
        }
    };
    
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };
    
    return (
        <>
            <Header 
                theme={theme}
                messageCount={messageCount}
            />
            <NavTabs
                user={user}
                setValue={setValue}
                value={0}
                theme={theme}
            />
            <div className={classes.root}>
            <div className={classes.content}>
                <h1 className={classes.title}>Hobbies</h1>
                <Paper elevation={3} className={classes.paper}>
                        <div className={classes.hobbyGrid}>
                            {hobbies.map((hobby, index) => (
                                <Paper
                                    key={index}
                                    className={`${classes.hobbyCard} ${hobby.selected ? classes.selectedHobby : ''}`}
                                    onClick={() => toggleHobby(index)}
                                >
                                    <img className={classes.hobbyImage} src={hobby.badge_url} alt={hobby.name} />
                                    <p className={classes.hobbyName}>{hobby.name}</p>
                                </Paper>
                            ))}
                        </div>
                        <div className={classes.saveButtonContainer}>
                            <Button
                                theme={theme}
                                onClick={saveHobbies}
                            >
                                Save
                            </Button>
                        </div>
                    </Paper>
                </div>
                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity={severity}>
                        {errorMessage}
                    </Alert>
                </Snackbar>
            </div>
        </>
    );
}