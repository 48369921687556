import { useState, useCallback, useEffect } from "react";
import axios from "axios";
import Grid from "@material-ui/core/Grid";
import Button from "../Input/Button";
import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import BrAvatar from "../Profile/Avatar";

const { REACT_APP_API_URL } = process.env;

export default function SelectChildUser(props) {
    const useStyles = makeStyles((theme) => ({
        button: {
            width: "120px",
            height: "25px",
            borderRadius: "12px",
            fontSize: "10px",
            margin: "5px"
        },
        root: {
            width: "100vw",
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            backgroundColor: "#FFFFFF",
            overflow: "auto"
        },
        bullet: {
            minHeight: "100px",
            margin: "0 2px",
            transform: "scale(0.8)"
        },
        title: {
            fontSize: 32,
            fontFamily: "GoodUnicorn",
            color: "#bbb"
        },
        margin: {
            margin: theme.spacing(1)
        },
        modal: {
            width: "30%", 
            minWidth: "300px",
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            borderRadius: "8px",
            border: "1px solid #e0e0e0",
            position: "relative",
            zIndex: 10
        },
        divider: {
            margin: 10,
        },
        selectedAvatar: {
            border: "3px solid #bc40fb",
            borderRadius: "50%"
        },
        deselectedAvatar: {
            border: "3px solid #FFF"
        },
        footer: {
            backgroundColor: '#b4fa64',
            width: '100%',
            padding: theme.spacing(2),
            marginTop: 'auto',
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 999
        },
        footerLink: {
            margin: "0 15px",
            color: "#333",
            textDecoration: "none",
            fontWeight: "bold",
            "&:hover": {
                textDecoration: "underline"
            }
        },
        proceedButton: {
            minWidth: "120px",
            padding: "10px 20px",
            backgroundColor: "#E040FB",
            color: "white",
            "&:hover": {
                backgroundColor: "#bc40fb"
            },
            "&:disabled": {
                backgroundColor: "#e0e0e0"
            }
        },
        childContainer: {
            cursor: "pointer",
            padding: "10px",
            borderRadius: "8px",
            transition: "all 0.2s ease",
            "&:hover": {
                backgroundColor: "rgba(224, 64, 251, 0.05)"
            }
        },
        childSelected: {
            backgroundColor: "#ff6419",
        },
        childName: {
            marginTop: "8px",
            fontSize: "1rem",
            fontWeight: "500"
        },
        loadingText: {
            marginTop: "5px",
            fontSize: "0.8rem",
            color: "#666"
        },
        modalTitle: {
            textAlign: "center",
            fontSize: "1.5rem", 
            fontWeight: "bold",
            marginBottom: "20px",
            color: "#555"
        },
        avatarContainer: {
            display: "flex",
            justifyContent: "center",
            marginBottom: "10px"
        },
        logoContainer: {
            width: "100%",
            height: "35vh", // Limit height to 35% of viewport height
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#1E88E5", // Blue background color
            padding: "10px 0",
            marginBottom: "0px",
            overflow: "hidden" // Prevent logo from overflowing
        },
        logoImage: {
            width: "auto",
            maxWidth: "450px", // Reduced max width
            maxHeight: "28vh", // Limit height
            height: "auto",
            objectFit: "contain" // Ensure the image maintains aspect ratio
        },
        selectionContainer: {
            marginTop: "10px", // Reduced top margin
            marginBottom: "70px", // Reduced bottom margin for footer
            width: "100%",
            display: "flex",
            justifyContent: "center"
        }
    }));
    
    const classes = useStyles();
    const history = useHistory();
    
    const { kids, proceedWithChild } = props;
    const [selectedChild, setSelectedChild] = useState(kids?.[0] || {});
    const [isLoading, setIsLoading] = useState(false);
    
    // Generate child-specific theme based on gender
    const getChildTheme = (child) => {
        return child.gender === "M"
            ? {
                primaryColor: "#3344cc",
                secondaryColor: "#ababdc",
                barColor: "hsl(29deg 91% 79%)",
                backgroundLogo: "/images/m-racing.jpg"
            }
            : {
                primaryColor: "#eb4c8a",
                secondaryColor: "#fccfdf",
                barColor: "#fcedf3",
                backgroundLogo: "/images/f-meadow.jpg"
            };
    };
    
    // Function to fetch and store child settings
    const fetchChildSettings = useCallback(async (childId) => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${REACT_APP_API_URL}/user_settings/${childId}`, {
                headers: {
                    'Authorization': `Bearer ${window.localStorage.getItem('JWT_TOKEN')}`
                }}
            );
            
            window.localStorage.setItem('SELECTED_CHILD_SETTINGS', JSON.stringify(response.data));
            return response.data;
        } catch (error) {
            console.error('Failed to fetch child settings:', error);
            return null;
        } finally {
            setIsLoading(false);
        }
    }, []);
    
    // Initialize default child selection and settings fetch
    useEffect(() => {
        const initializeDefaultChild = async () => {
            if (kids?.length > 0) {
                const defaultChild = kids[0];
                setSelectedChild(defaultChild);
                window.localStorage.setItem("SELECTED_CHILD_ID", defaultChild.id);
                await fetchChildSettings(defaultChild.id);
            }
        };
        
        initializeDefaultChild();
    }, [kids, fetchChildSettings]);
    
    // Handler for child selection
    const handleChildSelection = async (child) => {
        setSelectedChild(child);
        window.localStorage.setItem("SELECTED_CHILD_ID", child.id);
        await fetchChildSettings(child.id);
    };
    
    // Handler for proceed button
    const handleProceed = async () => {
        if (!selectedChild || !selectedChild.id) {
            console.error('No child selected');
            alert('No child selected');
            return;
        }
        
        // Ensure we have the latest settings before proceeding
        const settings = await fetchChildSettings(selectedChild.id);
        if (settings) {
            proceedWithChild({
                ...selectedChild,
                settings: settings
            });
            history.push("/dashboard/parents");
        } else {
            console.error('Failed to fetch child settings before proceeding');
        }
    };
    
    // Calculate grid size based on number of children
    const getGridSize = () => {
        if (!kids?.length) return 12;
        // For 1-3 children, give each child 4 columns (3 per row)
        // For 4+ children, give each child 3 columns (4 per row)
        return kids.length <= 3 ? 4 : 3;
    };

    return (
        <div className={classes.root}>
            {/* BridgePal Logo Banner */}
            <div className={classes.logoContainer}>
                <img 
                    src="/images/Parent Portal Banner.svg" 
                    alt="BridgePal Kids Logo" 
                    className={classes.logoImage}
                />
            </div>
            
            <div className={classes.selectionContainer}>
                <Card className={classes.modal}>
                    <div className={classes.modalTitle}>
                        Select Child Profile
                    </div>
                    <Grid 
                        container 
                        spacing={3} 
                        justifyContent="center"
                    >
                        {kids?.map((child) => {
                            // Generate theme based on child's gender
                            const childTheme = getChildTheme(child);
                            
                            return (
                                <Grid
                                    key={`child-${child.id}`}
                                    item
                                    xs={getGridSize()}
                                    style={{
                                        textAlign: "center"
                                    }}
                                >
                                    <div 
                                        onClick={() => handleChildSelection(child)}
                                        className={`${classes.childContainer} ${selectedChild.id === child.id ? classes.childSelected : ''}`}
                                    >
                                        <div className={classes.avatarContainer}>
                                            <BrAvatar
                                                user={child} // Pass the child as the user prop
                                                theme={childTheme} // Use child-specific theme
                                                avatarStyles={{
                                                    cursor: "pointer",
                                                    width: "64px",
                                                    height: "64px",
                                                    margin: "auto"
                                                }}
                                            />
                                        </div>
                                        <div className={classes.childName}>
                                            {child.name}
                                        </div>
                                        {isLoading && child.id === selectedChild.id && (
                                            <div className={classes.loadingText}>
                                                Loading settings...
                                            </div>
                                        )}
                                    </div>
                                </Grid>
                            );
                        })}

                        <Grid 
                            item 
                            xs={12} 
                            style={{ 
                                textAlign: "center",
                                marginTop: "30px"
                            }}
                        >
                            <Button
                                theme={{
                                    secondaryColor: "#bc40fb",
                                    primaryColor: "#E040FB",
                                }}
                                onClick={handleProceed}
                                disabled={isLoading}
                                className={classes.proceedButton}
                            >
                                {isLoading ? "Loading..." : "PROCEED"}
                            </Button>
                        </Grid>
                    </Grid>
                </Card>
            </div>
            
            <footer className={classes.footer}>
                <a href="https://bridgepalkids.com" className={classes.footerLink}>BridgePal Website</a>
                <a href="https://bridgepalkids.com/terms" className={classes.footerLink}>BridgePal Terms of Use</a>
                <a href="https://bridgepalkids.com/privacy-policy" className={classes.footerLink}>BridgePal Privacy Policy</a>
                <a href="https://bridgepalkids.com/kids-privacy-policy" className={classes.footerLink}>BridgePal Kids Privacy</a>
            </footer>
        </div>
    );
}