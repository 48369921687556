import axios from 'axios';

const { REACT_APP_API_URL } = process.env;

export const refreshToken = async () => {
  // Add a flag to prevent multiple simultaneous refreshes
  if (window.isRefreshing) {
    return null;
  }
  
  try {
    window.isRefreshing = true;
    const response = await axios.post(`${REACT_APP_API_URL}/auth/refresh`);
    if (response.data?.access_token) {
      window.localStorage.setItem("JWT_TOKEN", response.data.access_token);
      return response.data.access_token;
    }
    return null;
  } catch (error) {
    console.error('Token refresh failed:', error);
    return null;
  } finally {
    window.isRefreshing = false;
  }
};

export const checkTokenValidity = async () => {
  try {
    const currentToken = window.localStorage.getItem('JWT_TOKEN');
    
    const response = await axios.get(`${REACT_APP_API_URL}/auth/verify-token`, {
      headers: {
        'Authorization': `Bearer ${currentToken}`
      }
    });
    
    return response.status === 200;
  } catch (error) {
    console.error('Token verification failed', error);
    return false;
  }
};