import { createContext, useContext, useState, useEffect } from 'react';
import Pusher from 'pusher-js';
import { refreshToken } from './authUtils';

const PusherContext = createContext();

export const usePusher = () => useContext(PusherContext);

export const PusherProvider = ({ children, user }) => {
  const [pusher, setPusher] = useState(false);
  const [friendRequestChannel, setFriendRequestChannel] = useState(null);
  const [notificationsChannel, setNotificationsChannel] = useState(null);
  const [messagesChannel, setMessagesChannel] = useState(null);

  useEffect(() => {
    // If no user is selected, disconnect and reset
    if (!user?.id) {
      if (pusher) {
        pusher.disconnect();
        setPusher(null);
        setFriendRequestChannel(null);
        setNotificationsChannel(null);
        setMessagesChannel(null);
      }
      return;
    }

    const initializePusher = async () => {
      try {
        // Ensure we have a valid token
        const token = await refreshToken();
        
        if (!token) {
          console.error('Failed to obtain valid token for Pusher');
          return;
        }

        // Initialize Pusher with comprehensive configuration
        const newPusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
          cluster: process.env.REACT_APP_PUSHER_CLUSTER,
          encrypted: process.env.REACT_APP_PUSHER_ENCRYPTED === 'true',
          authorizer: (channel, options) => {
            return {
              authorize: async (socketId, callback) => {
                try {
                  const currentToken = await refreshToken();
                  
                  if (!currentToken) {
                    callback(new Error('Authentication failed'), null);
                    return;
                  }
    
                  const response = await fetch(
                    `${process.env.REACT_APP_API_URL}/broadcasting/auth`, 
                    {
                      method: 'POST',
                      headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${currentToken}`
                      },
                      body: JSON.stringify({
                        socket_id: socketId,
                        channel_name: channel.name
                      })
                    }
                  );
    
                  if (!response.ok) {
                    const errorText = await response.text();
                    console.error('Authorization failed:', errorText);
                    callback(new Error('Authorization failed'), null);
                    return;
                  }
    
                  const data = await response.json();
                  callback(null, data);
                } catch (error) {
                  console.error('Pusher Authorization Error:', error);
                  callback(error, null);
                }
              },
              // Add error handling
              disableStats: true,
              disableProtocolChecks: true
            };
          }
        });

        // Subscribe to user-specific channels
        const friendRequestChan = newPusher.subscribe(`private-friend-requests.${user.id}`);
        const notificationsChan = newPusher.subscribe(`private-notifications.${user.id}`);
        const messagesChan = newPusher.subscribe(`private-messages.${user.id}`);

        // Event listeners for different channels
        friendRequestChan.bind('notification', (data) => {
          console.log('Friend request notification received:', data);
          // Add your state update or callback logic here
        });

        notificationsChan.bind('notification', (data) => {
          console.log('General notification received:', data);
          // Add your state update or callback logic here
        });

        messagesChan.bind('message', (data) => {
          console.log('New message received:', data);
          // Add your state update or callback logic here
        });

        // Update state
        setPusher(newPusher);
        setFriendRequestChannel(friendRequestChan);
        setNotificationsChannel(notificationsChan);
        setMessagesChannel(messagesChan);

      } catch (error) {
        console.error('Error initializing Pusher:', error);
      }
    };

    // Initialize Pusher
    // initializePusher();

    // Cleanup function
    return () => {
      if (pusher) {
        // Unbind all events
        friendRequestChannel?.unbind_all();
        notificationsChannel?.unbind_all();
        messagesChannel?.unbind_all();

        // Unsubscribe from channels
        pusher.unsubscribe(`private-friend-requests.${user.id}`);
        pusher.unsubscribe(`private-notifications.${user.id}`);
        pusher.unsubscribe(`private-messages.${user.id}`);

        // Disconnect Pusher
        pusher.disconnect();

        // Reset state
        setPusher(null);
        setFriendRequestChannel(null);
        setNotificationsChannel(null);
        setMessagesChannel(null);
      }
    };
  }, [user?.id]); // Re-run when user ID changes

  // Provide Pusher instance and channels through context
  const pusherContextValue = {
    pusher,
    friendRequestChannel,
    notificationsChannel,
    messagesChannel
  };

  return (
    <PusherContext.Provider value={pusherContextValue}>
      {children}
    </PusherContext.Provider>
  );
};