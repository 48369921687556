import axios from "axios";
import { useState } from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import Switch from "@material-ui/core/Switch";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import TermsAndConditionsModal from "../Modals/TermsAndConditionsModal";
import TimeManagementModal from "../Modals/TimeManagementModal";
import PaymentSignUp from "../Modals/PaymentSignUp";
import Button from "../Input/Button";
import PermissionToggle from "./PermissionToggle";

const { REACT_APP_API_URL } = process.env;

/**
 * ParentsPortal Component
 * 
 * Provides an interface for parents to manage their child's application permissions and childSettings.
 * This includes controlling access to various features like chat, online resources, and content visibility.
 * 
 * @param {Object} props - Component props
 * @param {Object} props.user - The current user object containing user data
 * @param {Object} props.theme - Theme configuration for styling
 * @returns {JSX.Element} The rendered parent portal interface
 */
export default function ParentsPortal({ user, isParent, isMentor, theme }) {
    // Retrieve stored childSettings from localStorage
    const childSettings = JSON.parse(window.localStorage.getItem('SELECTED_CHILD_SETTINGS') || 'null');
    
    // Styles configuration
    const useStyles = makeStyles({
        button: {
            width: "80px",
            height: "25px",
            borderRadius: "12px",
            fontSize: "10px",
            margin: "5px"
        },
        root: {
            minHeight: 500,
            minWidth: 258
        },
        bullet: {
            minHeight: "100px",
            margin: "0 2px",
            transform: "scale(0.8)"
        },
        title: {
            fontSize: 32,
            fontFamily: "GoodUnicorn",
            color: theme.primaryColor
        },
        modal: {
            position: "absolute",
            width: 500,
            border: "2px solid #000",
            top: "30%",
            left: "30%"
        },
        pos: {
            marginBottom: 12
        },
        toggle: {
            margin: "10%",
            borderRadius: "15px",
            backgroundColor: "#bbb",
            textAlign: "center"
        },
        divider: {
            margin: 10
        }
    });
    
    const classes = useStyles();
    
    // Initialize all permission states
    const [permissionStates, setPermissionStates] = useState({
        homePagePosts: childSettings ? childSettings.home_page_posts === 1 : false,
        lifeTopics: childSettings ? childSettings.life_topics === 1 : false,
        xcPals: childSettings ? childSettings.xc_pals === 1 : false,
        journal: childSettings ? childSettings.journal === 1 : false,
        childPersonalProfile: childSettings ? childSettings.child_personal_profile === 1 : false,
        onlineResources: childSettings ? childSettings.online_resources === 1 : false,
        chatAllowed: childSettings ? childSettings.chat_allowed === 1 : false,
        profileHidden: childSettings ? childSettings.profile_hidden === 1 : false
    });
    
    // Modal states
    const [modalStates, setModalStates] = useState({
        personalInfo: false,
        timeManagement: false,
        termsAndConditions: false,
        subscribe: false
    });
    
    /**
     * Generic handler for updating user permissions
     * @param {string} permission - The permission key to update
     * @param {boolean} value - The new value for the permission
     */
    const handlePermissionUpdate = async (permission, value) => {
        try {
            const response = await axios(`${REACT_APP_API_URL}/user_settings/${window.localStorage.getItem('SELECTED_CHILD_ID')}`, {
                method: "POST",
                data: { [permission]: value ? 1 : 0 }
            });
            
            if (response.status === 200) {
                // Update local state first
                const permissionKey = Object.keys(permissionStates).find(
                    key => permissionConfigs.find(config => config.dbKey === permission)?.key === key
                );
                
                if (permissionKey) {
                    setPermissionStates(prevStates => ({
                        ...prevStates,
                        [permissionKey]: value
                    }));
                    
                    // Update localStorage
                    const currentSettings = JSON.parse(window.localStorage.getItem('SELECTED_CHILD_SETTINGS') || '{}');
                    const updatedSettings = {
                        ...currentSettings,
                        [permission]: value ? 1 : 0
                    };
                    window.localStorage.setItem('SELECTED_CHILD_SETTINGS', JSON.stringify(updatedSettings));
                }
            }
        } catch (error) {
            console.error(`Failed to update ${permission}:`, error);
        }
    };
    
    /**
     * Toggle handler for modal states
     * @param {string} modalName - Name of the modal to toggle
     */
    const toggleModal = (modalName) => {
        setModalStates(prevStates => ({
            ...prevStates,
            [modalName]: !prevStates[modalName]
        }));
    };
    
    // Custom styled switch component
    const GreySwitch = withStyles({
        switchBase: {
            color: theme.primaryColor,
            "&$checked": {
                color: theme.primaryColor,
            },
            "&$checked + $track": {
                backgroundColor: theme.primaryColor,
            },
        },
        checked: {},
        track: {}
    })(Switch);
    
    // Permission configurations for rendering
    const permissionConfigs = [
        {
            key: 'homePagePosts',
            dbKey: 'home_page_posts',
            label: "Home Page Posts",
            description: "Controls access to viewing and creating home page posts",
            state: permissionStates.homePagePosts
        },
        {
            key: 'lifeTopics',
            dbKey: 'life_topics',
            label: "Life Topics Access",
            description: "Controls access to life topics and related content",
            state: permissionStates.lifeTopics
        },
        {
            key: 'xcPals',
            dbKey: 'xc_pals',
            label: "XC Pals Feature",
            description: "Controls access to the XC Pals social features",
            state: permissionStates.xcPals
        },
        {
            key: 'journal',
            dbKey: 'journal',
            label: "Journal Access",
            description: "Controls access to journal features",
            state: permissionStates.journal
        },
        {
            key: 'childPersonalProfile',
            dbKey: 'child_personal_profile',
            label: "Personal Profile",
            description: "Controls access to personal profile features",
            state: permissionStates.childPersonalProfile
        },
        {
            key: 'onlineResources',
            dbKey: 'online_resources',
            label: "Online Resources",
            description: "Controls access to online educational resources",
            state: permissionStates.onlineResources
        },
        {
            key: 'chatAllowed',
            dbKey: 'chat_allowed',
            label: "Chat Permission",
            description: "Allows/disallows access to chat with XC Pals",
            state: permissionStates.chatAllowed
        },
        {
            key: 'profileHidden',
            dbKey: 'profile_hidden',
            label: "Hidden Profile",
            description: "Controls profile visibility to other users",
            state: permissionStates.profileHidden
        }
    ];
    
    // Only render for parent users
    if (!isParent && !isMentor) {
        return null;
    }
    
    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={1} />
                <Grid className={classes.title} item xs={11}>
                    Parent Portal
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={8}>
                    <Paper elevation={0} className={classes.root}>
                        <FormControl component="fieldset">
                            <FormGroup>
                                {/* Personal Information Section */}
                                <FormLabel onClick={() => toggleModal('personalInfo')} focused={false}>
                                    Edit Personal Information
                                    <Button theme={theme} onClick={() => toggleModal('personalInfo')}>
                                        Edit
                                    </Button>
                                </FormLabel>
                                <FormHelperText>Edit name or country</FormHelperText>
                                <Divider className={classes.divider} />
                                
                                {/* Updated Permission Toggles */}
                                {permissionConfigs.map(({ key, dbKey, label, description, state }) => (
                                    <div key={key}>
                                        <FormLabel focused={false}>
                                            {label}{" "}
                                            <PermissionToggle
                                                GreySwitch={GreySwitch}
                                                checked={permissionStates[key]}
                                                onChange={(e) => handlePermissionUpdate(dbKey, e.target.checked)}
                                                name={key}
                                            />
                                        </FormLabel>
                                        {description && (
                                            <FormHelperText>{description}</FormHelperText>
                                        )}
                                        <Divider className={classes.divider} />
                                    </div>
                                ))}
                                
                                {/* Time Management Section */}
                                <FormLabel onClick={() => toggleModal('timeManagement')} focused={false}>
                                    Manage Time
                                    <Button 
                                        theme={theme} 
                                        onClick={(e) => {
                                            e.stopPropagation(); // Prevent double firing
                                            toggleModal('timeManagement');
                                        }}
                                    >
                                        Edit
                                    </Button>
                                </FormLabel>
                                <FormHelperText>
                                    Set a time limit for your kids to use the app
                                </FormHelperText>
                                <Divider className={classes.divider} />
                                
                                {/* Subscribe Section */}
                                <FormLabel focused={false}>
                                    Payment
                                    <Button theme={theme} onClick={() => toggleModal('subscribe')}>
                                        Payment
                                    </Button>
                                </FormLabel>
                                <Divider className={classes.divider} />
                            </FormGroup>
                        </FormControl>
                    </Paper>
                </Grid>
            </Grid>
            
            {/* Modals */}
            <TimeManagementModal
                user={user}
                theme={theme}
                open={modalStates.timeManagement}
                handleClose={() => toggleModal('timeManagement')}
            />
            <TermsAndConditionsModal
                open={modalStates.termsAndConditions}
                theme={theme}
                handleClose={() => toggleModal('termsAndConditions')}
            />
            <PaymentSignUp
                open={modalStates.subscribe}
                theme={theme}
                handleClose={() => toggleModal('subscribe')}
            />
        </div>
    );
}