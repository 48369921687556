import { useState, useEffect } from "react";
import CountriesMap from "../Profile/CountriesMap";
import { TextField, Typography, Checkbox, FormControlLabel, Modal, Grid, MenuItem, FormControl, InputLabel, Select, OutlinedInput, FormHelperText } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { useForm, Controller } from "react-hook-form";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import Button from "../Input/Button";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ParentLogin from "./ParentLogin";

const { REACT_APP_API_URL } = process.env;

const useStyles = makeStyles((theme) => ({
    modalOverlay: {
        backgroundColor: '#FFFFFF !important', // Completely white overlay
        opacity: '1 !important', // Fully opaque
        // Don't cover the footer
        top: '0 !important',
        bottom: '100px !important', // Approximately the height of the footer
    },
    root: {
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundImage: 'linear-gradient(to bottom, #3f51b5, #ffffff)',
    },
    rootWithModal: {
        backgroundColor: '#ffffff', // White background when modal is open
    },
    header: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#3f51b5', // Fallback color in case the image doesn't load
    },
    headerImage: {
        width: '25%',
        height: 'auto',
        maxHeight: '250px', // Adjust this value based on your desired header height
    },
    content: {
        display: 'flex',
        justifyContent: 'space-between', // Changed from 'center' to 'space-between'
        width: '100%',
        maxWidth: '1200px', // Increased from '1000px' to accommodate more space
        margin: theme.spacing(4, 'auto'),
        padding: theme.spacing(0, 4), // Added horizontal padding
    },
    
    loginCard: {
        backgroundColor: '#ff6419',
        borderRadius: theme.spacing(2),
        padding: theme.spacing(7),
        margin: theme.spacing(0),
        width: '325px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    logo: {
        width: '200px',
        marginBottom: theme.spacing(1),
    },
    loginTitle: {
        color: '#b4fa64',
        marginBottom: theme.spacing(2),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    input: {
        marginBottom: theme.spacing(2),
        '& .MuiOutlinedInput-root': {
            backgroundColor: '#ffffff',
        },
    },
    checkboxLabel: {
        color: '#ffffff',
    },
    button: {
        backgroundColor: '#b4fa64',
        color: '#000000',
        '&:hover': {
            backgroundColor: '#a3e957',
        },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    footer: {
        backgroundColor: '#b4fa64',
        width: '100%',
        padding: theme.spacing(3),
        marginTop: 'auto',
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        marginTop: theme.spacing(2),
    },
    loginButton: {
        flex: 1,
        marginRight: theme.spacing(1),
    },
    registerButton: {
        flex: 1,
        marginLeft: theme.spacing(1),
        marginTop: theme.spacing(.25),
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalContent: {
        width: '500px', // Fixed width for consistency
        maxWidth: '90%', // Responsive
        backgroundColor: '#FF6419', // Keep the original orange background
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        borderRadius: 12,
        outline: 'none',
    },
    modalTitle: {
        color: '#B4FA64', // Keep the original green text
        textAlign: 'center',
        padding: 24,
        fontSize: 30,
        fontFamily: 'NeueHassDisplayMedium',
    },
    modalInput: {
        '& .MuiOutlinedInput-root': {
            background: 'white',
            fontFamily: 'NeueHassDisplayMedium',
            borderRadius: 16,
        },
    },
    
    // This style will be applied when we want to hide the label
    hiddenLabel: {
        '& .MuiInputLabel-outlined': {
            display: 'none',
        },
    },
    modalRememberMe: {
        paddingTop: 20,
        '& .MuiTypography-root': {
            color: '#B4FA64', // Keep the original green text
        },
        '& .MuiCheckbox-root': {
            color: '#B4FA64', // Keep the original green checkbox
        },
    },
    modalSubmitButton: {
        width: '80%',
        textTransform: 'capitalize',
        backgroundColor: '#B4FA64',
        color: '#FF6419',
        fontSize: '24px',
        borderRadius: '24px',
        fontFamily: 'NeueHassDisplayRoman',
    },
    footerLink: {
        color: '#ff6419',
        textDecoration: 'none',
        marginRight: theme.spacing(3),
        marginLeft: theme.spacing(3),
        marginBottom: theme.spacing(2),
        fontSize: '28px',
        textDecoration: 'underline',
        fontWeight: 900,
        fontFamily: '"Lato", "NeueHaasGroteskDisplay Pro", Arial, sans-serif',
        '&:hover': {
            textDecoration: 'underline'
        }
    },
    formControl: {
        width: '100%',
        '& .MuiOutlinedInput-root': {
            background: 'white',
            fontFamily: 'NeueHassDisplayMedium',
            borderRadius: 16,
        },
    },
    hiddenLabel: {
        display: 'none'
    },
    fieldLabel: {
        fontWeight: 300, // Lighter weight to match placeholders
        color: 'rgba(0, 0, 0, 0.6)', // Match placeholder color
        fontSize: '1rem',
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
    },
    hiddenLabel: {
        display: 'none'
    },
    placeholderSelect: {
        color: 'rgba(0, 0, 0, 0.6)', // Match placeholder color
        fontWeight: 300, // Lighter weight to match placeholders
    }
}));

const buttonTheme = {
    primaryColor: '#b4fa64',
    secondaryColor: '#000000',
    fontSize: '16px',
    borderRadius: '8px',
    fontFamily: 'NeueHassDisplayMedium'
};

export default function UnifiedLogin(props) {
    const classes = useStyles();
    const { register: registerKid, handleSubmit: handleSubmitKid } = useForm();
    const { control: registerControl, register: registerForm, handleSubmit: handleSubmitRegister } = useForm();
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const history = useHistory();
    const { setUser, user } = props;
    const [isModalOpen, setIsModalOpen] = useState(searchParams.get("register-new-user") === "true");
    const [countrySelected, setCountrySelected] = useState(false);
    const [genderSelected, setGenderSelected] = useState(false);
    const [successfulRegistration, setSuccessfulRegistration] = useState(false);
    
    
    useEffect(() => {
        if (successfulRegistration) {
            setTimeout(() => {
                alert(`Thank you for joining BridgePal Kids. A verification and permission email has been sent to your parent/guardian.\n\nYou will be able to access your account after parental verification.`);
            }, 500);
        }
    }, [successfulRegistration]);
    
    const childLogin = async (data) => {
        try {
            const result = await axios.post(`${REACT_APP_API_URL}/auth/login`, data);
            window.localStorage.setItem("JWT_TOKEN", result.data.access_token);
            window.localStorage.setItem("USER", JSON.stringify(result.data));
            window.localStorage.setItem("SETTINGS", JSON.stringify(result.data.settings));
            setUser(result.data);
            history.push("/dashboard/home");
        } catch (error) {
            console.log("🚀 ~ file: UnifiedLogin.jsx ~ childLogin ~ error:", error);
            alert("Login failed. Please try again.");
        }
    };
    
    const onSubmitRegisterKid = async (data) => {
        console.log("🚀 ~ file: UnifiedLogin.jsx:233 ~ onSubmitRegisterKid ~ data:", {data});
        try {
            const axiosResponse = await axios.post(`${REACT_APP_API_URL}/auth/register`, data);
            if (axiosResponse.status === 200 || axiosResponse.status === 201) {
                console.log("🚀 ~ file: UnifiedLogin.jsx:237 ~ onSubmitRegisterKid ~ Registration Successful!");
                setIsModalOpen(false);
                setSuccessfulRegistration(true);
                history.push("/");
            };
        } catch (error) {
            alert("Unsuccessful registration");
            console.error("Registration error:", error);
            if (error.response) {
                // The request was made and the server responded with a status code that falls out of the range of 2xx
                console.error("Error Response:", error.response);
                alert(error.response.data.message || "Registration failed. Please try again.");
            } else if (error.request) {
                // The request was made but no response was received
                console.error("No response received:", error.request);
                alert("No response from server. Please check your internet connection and try again.");
            } else {
                // Something happened in setting up the request that triggered an Error
                console.error("Error message:", error.message);
                alert("An error occurred. Please try again.");
            }
        }
    };
    
    const handleOpenModal = () => {
        setIsModalOpen(true);
    };
    
    const handleCloseModal = (shouldNavigate = false) => {
        // setIsModalOpen(false);
        if (shouldNavigate) {
            window.location.href = "https://bridgepalkids.com/";
            return;
        }
    };
    
    if (user) return <Redirect to="/dashboard/home" />;
    
    return (
        <div className={`${classes.root} ${isModalOpen ? classes.rootWithModal : ''}`}>
            <header className={classes.header}>
                <img 
                    src="/images/Login_Title.png" 
                    alt="BridgePal Kids Login" 
                    className={classes.headerImage}
                />
            </header>
            
            <div className={classes.content}>
                <div className={classes.loginCard}>
                    <Typography variant="h3" className={classes.loginTitle}>Kid's Login</Typography>
                    <form id="kid-login-form" onSubmit={handleSubmitKid(childLogin)}>
                        <TextField
                            className={classes.input}
                            placeholder="Kid's Username"
                            variant="outlined"
                            fullWidth
                            {...registerKid("username")}
                        />
                        <TextField
                            className={classes.input}
                            placeholder="Kid's Password"
                            variant="outlined"
                            fullWidth
                            type="password"
                            {...registerKid("password")}
                        />
                        <FormControlLabel
                            control={<Checkbox {...registerKid("rememberMe")} />}
                            label="Remember me"
                            className={classes.checkboxLabel}
                        />
                        <div className={classes.buttonContainer}>
                            <Button
                                type="submit"
                                form="kid-login-form"
                                className={`${classes.button} ${classes.loginButton}`}
                                theme={buttonTheme}
                                onClick={handleSubmitKid(childLogin)}
                            >
                                Login
                            </Button>
                            <Button
                                className={`${classes.button} ${classes.registerButton}`}
                                theme={buttonTheme}
                                onClick={handleOpenModal}
                            >
                                Register
                            </Button>
                        </div>
                    </form>
                </div>
                
                <ParentLogin />
            </div>
            
            <Modal
                open={isModalOpen}
                onClose={() => handleCloseModal(true)}
                className={classes.modal}
                BackdropProps={{
                    className: classes.modalOverlay
                }}
            >
                <div className={classes.modalContent}>
                    <Typography variant="h5" className={classes.modalTitle}>
                        Sign-Up
                    </Typography>
                    <form id="registration-form" onSubmit={handleSubmitRegister(onSubmitRegisterKid)}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    placeholder="Kid's First Name"
                                    fullWidth
                                    {...registerForm("name")}
                                    variant="outlined"
                                    className={classes.modalInput}
                                />
                            </Grid>
                            <Grid item xs={6}>
                            <Controller
                                control={registerControl}
                                name="country"
                                defaultValue=""
                                rules={{ required: true }}
                                render={({ field, fieldState: { error } }) => {
                                    const { onChange, value } = field;
                                    
                                    const handleSelectChange = (e) => {
                                    onChange(e);
                                    setCountrySelected(true);
                                    };
                                    
                                    const handleOpen = () => setCountrySelected(true);
                                    
                                    return (
                                    <FormControl variant="outlined" className={classes.formControl} error={!!error}>
                                        {/* Only show label when needed for accessibility, but hide visually */}
                                        <InputLabel 
                                            shrink={true} 
                                            className={classes.hiddenLabel}
                                            >
                                            Country
                                        </InputLabel>
                                        <Select
                                            value={value || ''}
                                            onChange={handleSelectChange}
                                            onOpen={handleOpen}
                                            displayEmpty
                                            // Apply placeholder styling to the rendered value
                                            renderValue={(selected) => (
                                                <span className={!selected ? classes.placeholderSelect : ''}>
                                                {selected || 'Country'}
                                                </span>
                                            )}
                                            // Use empty label to avoid the floating label behavior
                                            label=""
                                            input={<OutlinedInput notched={false} label="" />}
                                            >
                                            {Object.entries(CountriesMap).map(([code, name]) => (
                                                <MenuItem key={code} value={code}>
                                                {name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {error && <FormHelperText>Country is required</FormHelperText>}
                                    </FormControl>
                                    );
                                }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Controller
                                    control={registerControl}
                                    name="dob"
                                    defaultValue={null}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="EN-US">
                                            <DatePicker
                                                value={value}
                                                onChange={onChange}
                                                slotProps={{
                                                    textField: {
                                                        placeholder: "Kid's DOB",
                                                        fullWidth: true,
                                                        error: !!error,
                                                        helperText: error?.message,
                                                        className: classes.modalInput
                                                    },
                                                }}
                                            />
                                        </LocalizationProvider>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                            <Controller
                                control={registerControl}
                                name="gender"
                                defaultValue=""
                                rules={{ required: true }}
                                render={({ field, fieldState: { error } }) => {
                                    const { onChange, value } = field;
                                    
                                    const handleSelectChange = (e) => {
                                        onChange(e);
                                        setGenderSelected(true);
                                    };
                                    
                                    const handleOpen = () => setGenderSelected(true);
                                    
                                    return (
                                    <FormControl variant="outlined" className={classes.formControl} error={!!error}>
                                        {/* Only show label when needed for accessibility, but hide visually */}
                                        <InputLabel 
                                            shrink={true} 
                                            className={classes.hiddenLabel}
                                            >
                                            Gender
                                        </InputLabel>
                                        <Select
                                            value={value || ''}
                                            onChange={handleSelectChange}
                                            onOpen={handleOpen}
                                            displayEmpty
                                            // Apply placeholder styling to the rendered value
                                            renderValue={(selected) => (
                                                <span className={!selected ? classes.placeholderSelect : ''}>
                                                {selected || 'Gender'}
                                                </span>
                                            )}
                                            // Use empty label to avoid the floating label behavior
                                            label=""
                                            input={<OutlinedInput notched={false} label="" />}
                                            >
                                            <MenuItem value="M">Male</MenuItem>
                                            <MenuItem value="F">Female</MenuItem>
                                        </Select>
                                        {error && <FormHelperText>Gender is required</FormHelperText>}
                                    </FormControl>
                                    );
                                }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    placeholder="Kid's Username"
                                    variant="outlined"
                                    fullWidth
                                    {...registerForm("username")}
                                    className={classes.modalInput}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    placeholder="Kid's Password"
                                    variant="outlined"
                                    fullWidth
                                    type="password"
                                    {...registerForm("password")}
                                    className={classes.modalInput}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    placeholder="Parent's Email Address"
                                    variant="outlined"
                                    fullWidth
                                    {...registerForm("email")}
                                    className={classes.modalInput}
                                />
                            </Grid>
                            <Grid item xs={12} className={classes.modalRememberMe}>
                                <FormControlLabel
                                    control={<Checkbox {...registerForm("rememberMe")} />}
                                    label="Remember Me"
                                />
                            </Grid>
                            <Grid item xs={12} style={{ textAlign: 'center' }}>
                                <Button
                                    className={classes.modalSubmitButton}
                                    type="submit"
                                    onClick={handleSubmitRegister(onSubmitRegisterKid)}
                                >
                                    Create Account
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </Modal>
            
            
            <footer className={classes.footer} style={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 999 }}>
                <a href="https://bridgepalkids.com" className={classes.footerLink}>BridgePal Website</a>
                <a href="https://bridgepalkids.com/terms" className={classes.footerLink}>BridgePal Terms of Use</a>
                <a href="https://bridgepalkids.com/privacy-policy" className={classes.footerLink}>BridgePal Privacy Policy</a>
                <a href="https://bridgepalkids.com/kids-privacy-policy" className={classes.footerLink}>BridgePal Kids Privacy</a>
            </footer>
        </div>
    );
};