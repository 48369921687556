import axios from "axios";
import { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { Card } from "@material-ui/core";

const { REACT_APP_API_URL } = process.env;

export default function LifeTopics(props) {
    const { theme } = props;
    const useStyles = makeStyles({
        root: {
            minWidth: 275,
            minHeight: 500,
        },
        lifeTopicCard: {
            width: '100%',          // Take full width of grid item
            minHeight: 200,
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '16px',   // Add bottom margin for spacing
        },
        videoCardContainer: {
            width: '100%',          // Take full width
            maxHeight: 135,
            marginBottom: '8px',    // Space between video and text
        },
        input: {
            height: "500px",
            resize: "none",
        },
        title: {
            fontSize: 32,
            fontFamily: "GoodUnicorn",
            color: theme.primaryColor,
            marginBottom: '24px',   // Add space below title
        },
        playIcon: {
            color: theme.primaryColor,
            cursor: "pointer",
            position: "absolute",
            top: "40%",
            left: "50%",
            transform: "translate(-50%, -50%)"
        }
    });
    
    const [lifeTopics, setLifeTopics] = useState([]);
    
    useEffect(() => {
        const fetchLifeTopics = async () => {
            try {
                const result = await axios(`${REACT_APP_API_URL}/life_topics`);
                setLifeTopics(result.data);
            } catch (error) {
                console.error("Error fetching life topics:", error);
            }
        };
        
        fetchLifeTopics();
    }, []);
    
    const classes = useStyles();
    
    const options = { year: "numeric", month: "long", day: "numeric" };
    
    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={1} />
                <Grid className={classes.title} item xs={11}>
                    Life Topics
                </Grid>
                
                <Grid 
                    container 
                    spacing={3} 
                    style={{ 
                        padding: '0 24px',  // Add padding on sides
                        marginTop: '16px'   // Add space from title
                    }}
                >
                    {lifeTopics.map((topic, index) => (
                        <Grid 
                            item 
                            xs={12}     // Full width on mobile
                            sm={6}      // Two per row on tablet
                            md={3}      // Four per row on desktop
                            key={topic.id || index}
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-start'  // Align content to left
                            }}
                        >
                            <div className={classes.lifeTopicCard}>
                                <Card className={classes.videoCardContainer}>
                                    <div
                                        style={{
                                            position: "relative",
                                            paddingBottom: "56.25%",
                                            height: 0,
                                            overflow: "hidden"
                                        }}
                                    >
                                        <iframe
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                                position: "absolute",
                                                left: 0,
                                                top: 0,
                                                overflow: "hidden"
                                            }}
                                            type="text/html"
                                            src={topic.url}
                                            width="100%"
                                            height="100%"
                                            allowFullScreen
                                            title={`Life Topic ${index + 1}`}
                                        />
                                    </div>
                                </Card>
                                
                                <div style={{ 
                                    textAlign: "left",
                                    marginTop: '8px',
                                    padding: '0 8px'
                                }}>
                                    <span>
                                        <b>{topic.name}</b>
                                    </span>
                                    <br />
                                    <span>
                                        {new Date(topic.created_at).toLocaleDateString(
                                            "en-US",
                                            options
                                        )}
                                    </span>
                                </div>
                            </div>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </div>
    );
};