import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
    root: {
        display: "flex",
        alignContent: "center"
    },
    circle: {
        borderRadius: "50%",
        border: (props) => `2px solid ${props.theme.primaryColor}`
    }
});


export default function BrAvatar({ user, theme, avatarStyles, label, className }) {
    const history = useHistory();
    const classes = useStyles({ theme });
    
    const handleClick = () => {
        if (user?.id) {
            history.push(`/user/${user.id}`);
        }
    };
    
    return (
        <div className={`${classes.root} ${className}`} style={avatarStyles}>
            <Avatar
                onClick={handleClick}
                alt={user?.name || "User Avatar"}
                className={classes.circle}
                style={avatarStyles}
                src={user?.gender === "F" ? "/avatars/001-girl-1.png" : "/avatars/019-boy.png"}
            />
            {label && (
                <span
                    style={{
                        whiteSpace: "nowrap",
                        paddingTop: "10px",
                        fontWeight: "bold"
                    }}
                >
                    {label}
                </span>
            )}
        </div>
    );
};