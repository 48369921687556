import axios from "axios";
import { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "../Input/Button";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
const { REACT_APP_API_URL } = process.env;

export default function SelectAvatarModal(props) {
    const { user, theme } = props;
    
    const useStyles = makeStyles((theme2) => ({
        button: {
                width: "80px",
                height: "25px",
                borderRadius: "12px",
                fontSize: "10px",
                margin: "5px"
            },
            root: {
                minHeight: 500,
                minWidth: 258
            },
            bullet: {
                minHeight: "100px",
                margin: "0 2px",
                transform: "scale(0.8)"
            },
            title: {
                fontSize: 32,
                fontFamily: "GoodUnicorn",
                color: theme.primaryColor
            },
            margin: {
                margin: theme2.spacing(1)
            },
            modal: {
                backgroundColor: theme2.palette.background.paper,
                border: "2px solid #000",
                boxShadow: theme2.shadows[5],
                padding: theme2.spacing(2, 4, 3),
                position: "absolute",
                width: 300,
                maxHeight: 400,
                top: "30%",
                left: "30%",
                overflow: "scroll"
            },
            selectedAvatar: {
                border: `3px solid ${theme.primaryColor}`, //#eb4c8a',
                borderRadius: "50%"
            },
            deselectedAvatar: {
                border: "3px solid #FFF"
            },
            divider: {
                margin: 10
            }
    }));
    
    const [selectedAvatar, setSelectedAvatar] = useState(user?.avatar?.id ? user.avatar.id : user.avatar_id);
    const [avatars, setAvatars] = useState([]);
    
    useEffect(async () => {
        const avatars = await axios(`${REACT_APP_API_URL}/avatars`);
        setAvatars(avatars.data.filter( (item) => item.gender === user.gender));
    }, []);
    
    const setAvatar = async () => {
        await axios(`${REACT_APP_API_URL}/user/${user.id}/avatar`, {
            method: "POST",
            data: { new_avatar_id: selectedAvatar },
        });
        window.location.reload();
    };
    
    const classes = useStyles();
    
    const modalBody = (
        <div className={classes.modal}>
            <Grid container>
            {avatars.map((avatar, index) => {
                return (
                    <Grid key={index + 1 * Math.random()} item xs={3}>
                        <img
                            key={index + 1 * Math.random()}
                            onClick={() => setSelectedAvatar(avatar.id)}
                            src={`${REACT_APP_API_URL}/avatar/${avatar.url}`}
                            className={
                                avatar.id == selectedAvatar
                                ? classes.selectedAvatar
                                : classes.deselectedAvatar
                            }
                            style={{ width: "48px", height: "48px", margin: "5px" }}
                        />
                    </Grid>
                );
            })}
            
            <div style={{ width: "100%", textAlign: "center" }}>
                <Button theme={theme} onClick={setAvatar}>
                {" "}
                    Update
                {" "}
                </Button>
            </div>
            </Grid>
        </div>
    );
    
    return (
        <Modal
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            {modalBody}
        </Modal>
    );
};