import axios from "axios";
import { useEffect, useState, useMemo } from "react";
import { Dashboard } from "../Dashboard";
import BrAvatar from "./Avatar";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Messenger from "./Messenger";
import { usePusherConnection } from '../hooks/usePusherConnection';

const { REACT_APP_API_URL } = process.env;


export default function MessengerPage(props) {
    const { user, theme, isParent } = props;
    const [messages, setMessages] = useState([]);
    const [unreadMessages, setUnreadMessages] = useState([]);
    // The selectedUser is the user you are messaging
    const [selectedUser, setSelectedUser] = useState({});
    const [edit, setEdit] = useState(window.localStorage.getItem("USER")  || {});
    
    const [message, setMessage] = useState("");
    const [characterCount, setCharacterCount] = useState(0);
    
    const isPusherConnected = usePusherConnection(user);
    
    // console.log("🚀 ~ file: MessengerPage.jsx:23 ~ MessengerPage ~ user:", user);
    
    const useStyles = makeStyles({
        root: {
            minHeight: 500,
            maxHeight: 500,
        },
        bullet: {
            minHeight: "100px",
            margin: "0 2px",
            transform: "scale(0.8)",
        },
        conversation: {
            width: "100%",
            overflow: "scroll",
            height: "332px",
            paddingInlineStart: 0,
        },
        title: {
            fontSize: 32,
            fontFamily: "GoodUnicorn",
            color: theme.primaryColor,
        },
        avatar: {
            marginLeft: "30%",
            marginTop: "40%",
            width: "20%",
            height: "20%",
        },
        pos: {
            marginBottom: 12,
        },
        heading: {
            fontSize: 16,
            flexBasis: "33.33%",
            flexShrink: 0,
        },
        chatGrid: {
            marginLeft: "40px",
        },
        playIcon: {
            color: "#eb4c8a",
            cursor: "pointer",
            position: "absolute",
            top: "20%",
            left: "25%",
            transform: "translate(-25%, -25%)",
        },
        imagesCard: {
            backgroundColor: "#d6f1fe",
        },
        images: {
            margin: "4px",
        },
        toggle: {
            margin: "10%",
            borderRadius: "15px",
            backgroundColor: "#fffefe",
            textAlign: "center",
        },
        self: {
            justifyContent: "flex-end",
            alignItems: "flex-end",
        },
        selfChip: {
            background: "linear-gradient(90deg, #ffa0a4 20%, #eb4c8a 80%)",
            color: "white",
        },
        recipientChip: {
            background: "floralwhite",
        }
    });
    
    const classes = useStyles();
    
    const chipCustom = makeStyles({
        label: { whiteSpace: "normal", overflow: "auto", margin: "5px" },
    })();
    
    useEffect(() => {
        // Create a flag to prevent state updates after component unmount
        let isSubscribed = true;
        
        const fetchMessages = async () => {
            // Only fetch if we have both valid user and selectedUser
            if (!selectedUser?.id || !user?.id) return;
            
            try {
                const result = await axios(
                    `${REACT_APP_API_URL}/messages/${user.id}/${selectedUser.id}`
                );
                
                if (!isSubscribed) return; // Prevent state update if component unmounted
                
                if (result.status === 200) {
                    setMessages(result.data);
                    setMessage("");
                    
                    // Find messages that are unread and meant for current user
                    const unreadMessages = result.data.filter(
                        message => message.seen !== 1 && message.recipient_id === user.id
                    );
                    
                    // Update unread messages state
                    setUnreadMessages(unreadMessages);
                    
                    // If there are unread messages, mark them as seen
                    if (unreadMessages.length > 0) {
                        try {
                            // await axios.post(
                            //     `${REACT_APP_API_URL}/see_messages/${selectedUser.id}`,
                            //     { messages: unreadMessages }
                            // );
                            
                            // Refactored to use this newly added endpoint to mark all messages as seen for the authenticated user. If anything breaks, revert to the above
                            await axios.post(
                                `${REACT_APP_API_URL}/see_messages/me`,
                                { messages: unreadMessages }
                            );
                            
                            // Update local message state to reflect seen status
                            if (isSubscribed) {
                                setMessages(prevMessages => 
                                    prevMessages.map(msg => 
                                        unreadMessages.some(unread => unread.id === msg.id)
                                            ? { ...msg, seen: 1 }
                                            : msg
                                    )
                                );
                            }
                        } catch (error) {
                            console.error('Failed to mark messages as seen:', error);
                        }
                    }
                }
            } catch (error) {
                console.error('Failed to fetch messages:', error);
            }
        };
        
        fetchMessages();
        
        // Cleanup function
        return () => {
            isSubscribed = false;
        };
        
        // Only depend on IDs instead of entire selectedUser object
    }, [selectedUser?.id, user?.id]);
    
    const sendMessage = async () => {
        if(edit.id){
            const result = await axios(
                `${REACT_APP_API_URL}/messages/${edit.id}`,
                { method: "PUT", data: { content: message } }
            );
            if (result.status === 200) {
                messages.map( (item) => { if(item.id === result.data.id){ item.content = result.data.content; } return item; });
                setMessage("");
                setCharacterCount(0);
                setEdit({});
                setMessages([...messages]);
            }
        } else {
            const result = await axios(
                `${REACT_APP_API_URL}/send_message/${selectedUser.id}`,
                { method: "POST", data: { content: message } }
            );
            if (result.status === 201) {
                // Ensure we're using the server's timestamp
                const newMessage = {
                    sender_id: user.id,
                    recipient_id: selectedUser.id,
                    content: message,
                    id: result.data.message.id,
                    created_at: result.data.message.created_at // Use server timestamp
                };
                
                setMessages(prevMessages => [...prevMessages, newMessage]);
                setMessage("");
                setCharacterCount(0);
                setFirstMessages(prev => ({
                    ...prev,
                    [selectedUser.id]: newMessage
                }));
            }
        }
    };
    
    const handleMessageInput = (e) => {
        if (e.keyCode == 13) {
            return sendMessage();
        }
        setMessage(e.target.value.slice(0, 240));
        setCharacterCount(e.target.value.slice(0, 240).length);
    };
    
    const handleEdit = (message) => {
        setMessage(message.content);
        setEdit(message);
    };
    
    const handleRemove = async (message) => {
        console.log("Message: ", message);
        const result = await axios(
            `${REACT_APP_API_URL}/messages/${message.id}`,
            { method: "Delete", data: { content: message } }
        );
        setMessages(messages.filter(item => item.id !== message.id));
    }
    
    const [firstMessages, setFirstMessages] = useState({});
    
    const XCPalRows = useMemo(
        () => (props) => {
            const { friends, selectedUser, setSelectedUser, classes, theme } = props;
            return friends.map((pal) => (
                <XCPalRow
                    key={pal.id}
                    user={pal}
                    selectedUser={selectedUser}
                    firstMessages={firstMessages}
                    setSelectedUser={setSelectedUser}
                    classes={classes}
                    theme={theme}
                />
            ));
        },
        [selectedUser, firstMessages]
    );
    
    const XCPalRow = useMemo(
        () => (props) => {
                const {
                user,
                firstMessages,
                setSelectedUser,
                selectedUser,
                classes,
                theme
            } = props;
            
            return (
                <Paper
                    elevation={0}
                    style={
                        selectedUser && selectedUser.id === user.id
                            ? { backgroundColor: "#f1f1f1" }
                            : {}
                    }
                    className={classes.palrow}
                    onClick={() => setSelectedUser(user)}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <BrAvatar user={user} classes={classes} theme={theme} />
                        </Grid>
                        <Grid item xs={9}>
                            <Typography className={classes.xcName}>
                                {" "}
                                {props.user.name}{" "}
                            </Typography>
                            <Typography
                            style={{
                                maxHeight: "100px",
                                overflow: "auto",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                            }}
                            >
                            {" "}
                            {firstMessages[user.id]
                                ? firstMessages[user.id].content
                                : ""}{" "}
                            </Typography>
                        </Grid>
                    </Grid>
                </Paper>
            );
        },
        [classes, firstMessages, selectedUser]
    );
    
    const [friends, setFriends] = useState([]);
    
    // First useEffect for initial data fetching - runs once on mount
    useEffect(() => {
        if (!user?.id) return;
        let mounted = true;
        
        const fetchInitialData = async () => {
            try {
                const [friendsResult, firstMessagesResult] = await Promise.all([
                    axios(`${REACT_APP_API_URL}/friends/${user.id}`),
                    axios(`${REACT_APP_API_URL}/first_messages/${user.id}`)
                ]);
                
                if (!mounted) return;
                
                // Set friends
                setFriends(friendsResult.data);
                
                // Set selected user from URL hash or first friend
                const hashId = window.location.hash.slice(1);
                const selectedFriend = friendsResult.data.find(
                    friend => friend.id.toString() === hashId
                ) || friendsResult.data[0] || {};
                
                setSelectedUser(selectedFriend);
                
                // Set first messages
                if (friendsResult.data?.length) {
                    const messagesMap = {};
                    friendsResult.data.forEach(friend => {
                        messagesMap[friend.id] = firstMessagesResult.data.find(
                            msg => msg.recipient_id === friend.id || msg.sender_id === friend.id
                        );
                    });
                    setFirstMessages(messagesMap);
                }
            } catch (error) {
                console.error('Initial data fetch failed:', error);
            }
        };
        
        fetchInitialData();
        
        return () => {
            mounted = false;
        };
    }, [user?.id]); // Only depend on user.id
    
    // Separate useEffect for Pusher setup
    useEffect(() => {
        if (!isPusherConnected || !user?.id) return;
        let pusherChannel = null;
        
        const setupPusherChannel = () => {
            if (window.Echo) {
                pusherChannel = window.Echo.private(`messages.${user.id}`);
                
                pusherChannel.listen('.SentMessage', (event) => {
                    if (!event.message) return;
                    
                    if (event.message.sender_id === selectedUser?.id || 
                        event.message.recipient_id === selectedUser?.id) {
                        setMessages(prev => {
                            if (prev.some(msg => msg.id === event.message.id)) return prev;
                            const newMessages = [...prev, event.message];
                            return newMessages.sort((a, b) => 
                                new Date(a.created_at) - new Date(b.created_at)
                            );
                        });
                        setFirstMessages(prev => ({
                            ...prev,
                            [event.message.sender_id]: event.message
                        }));
                    }
                });
            }
        };
        
        setupPusherChannel();
        
        return () => {
            if (pusherChannel) {
                pusherChannel.stopListening('.SentMessage');
            }
        };
    }, [isPusherConnected, user?.id, selectedUser?.id]);
    
    return (
        <>
        <Dashboard initialTab={6} user={user} theme={theme} isParent={isParent} />
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={1} />
                    <Grid className={classes.title} item xs={11}>
                        Chat
                    </Grid>
                    <Grid item xs={1} />
                    <Grid item xs={3} className={classes.palGrid}>
                        <Card className={classes.root}>
                            <Paper className={classes.toggle}></Paper>
                            
                            <XCPalRows
                                friends={friends}
                                firstMessages={firstMessages}
                                selectedUser={selectedUser}
                                setSelectedUser={setSelectedUser}
                                classes={classes}
                                theme={theme}
                            />
                        </Card>
                    </Grid>
                    <Grid item xs={6} className={classes.chatGrid}>
                        <Messenger
                            characterCount={characterCount}
                            messages={messages}
                            message={message}
                            selectedUser={selectedUser}
                            sendMessage={sendMessage}
                            handleMessageInput={handleMessageInput}
                            handleEdit={handleEdit}
                            handleRemove={handleRemove}
                            edit={edit}
                            theme={theme}
                            user={user}
                            isParent={isParent}
                        />
                    </Grid>
                </Grid>
            </div>
        </>
    );
};