import Grid from "@material-ui/core/Grid";
import Button from "../Input/Button";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

export default function HiddenProfileMessage({ theme }) {
    const useStyles = makeStyles({
            root: {
                minWidth: 275,
                minHeight: 500,
            },
            title: {
                fontSize: 32,
                fontFamily: "GoodUnicorn",
                color: theme.primaryColor,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "300px",
            },
            heading: {
                fontSize: 16,
                flexBasis: "33.33%",
                flexShrink: 0,
            }
        });
    const classes = useStyles();
    const history = useHistory();
    return (
        <div>
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={1} />
                    <Grid className={classes.title} item xs={10}>
                        Profile Not Public
                    </Grid>
                </Grid>
            </div>
            
            <div style={{ textAlign: 'center', marginTop: '100px' }}>
                <Button
                    onClick={() => history.push("/dashboard/home")}
                    theme={theme}
                    style={{ 
                        fontWeight: 'bold',
                        padding: '10px 20px',
                        fontSize: '16px'
                    }}
                >
                    Dashboard
                </Button>
            </div>
        </div>
    );
}