import { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import BrAvatar from "./Avatar";
import Badge from "@material-ui/core/Badge";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { usePusherConnection } from '../hooks/usePusherConnection';

const { REACT_APP_API_URL } = process.env;


const Header = ({ theme, messageCount }) => {
    const useStyles = makeStyles((theme) => ({
        badge: {
            right: 10,
            top: 11,
            border: `2px solid ${theme.primaryColor}`,
            padding: "0 4px",
            justifyContent: "flex-end",
        },
        notifBadge: {
            right: 1,
            top: 15,
            border: `2px solid ${theme.primaryColor}`,
            padding: "0 4px",
            justifyContent: "flex-end",
        },
        root: {
            flexGrow: 1,
            justifyContent: "center",
        },
        topNav: {
            height: "75px",
            width: "100%",
        },
        control: {
            padding: theme.spacing(2),
        }
    }));
    
    const isPusherConnected = usePusherConnection();
    
    const user = JSON.parse(window.localStorage.getItem("USER") || "null");
    const settings = JSON.parse(window.localStorage.getItem('SETTINGS') || 'null');
    const children = JSON.parse(window.localStorage.getItem('CHILDREN') || 'null');
    const classes = useStyles();
    const history = useHistory();
    const [value, setValue] = useState(0);
    
    
    const SimpleMenu = () => {
        const [anchorEl, setAnchorEl] = useState(null);
        const [notifs, setNotifCount] = useState(null);
        
        useEffect(() => {
            if (!isPusherConnected) return;
            async function fetchNotifications() {
                if (user) {
                    try {
                        // If user is a parent, get pending friend requests for their children
                        if (children && children.length > 0 && window.Echo) {
                            const pendingRequests = await Promise.all(
                                children.map(child => 
                                    axios.get(`${REACT_APP_API_URL}/pending_friends/${child.id}`)
                                )
                            );
                            
                            const totalPending = pendingRequests.reduce((total, response) => 
                                total + response.data.length, 0
                            );
                            
                            setNotifCount(totalPending);
                            
                            // Listen for notifications
                            window.Echo.private(`notifications.${user.id}`).listen(
                                "SentNotification",
                                (e) => {
                                    console.log("Notification received:", e);
                                    if (e.data && typeof e.data.count !== 'undefined') {
                                        setNotifCount(e.data.count);
                                    }
                                }
                            );
                        }
                        
                        // If user is a child, also listen for friend request notifications
                        if (user.roles?.some(role => role.name === "child")) {
                            window.Echo.private(`friend-requests.${user.id}`).listen(
                                "SentNotification",
                                (e) => {
                                    console.log("Friend request notification received:", e);
                                    // Refresh friend requests count
                                    fetchNotifications();
                                }
                            );
                        }
                    } catch (error) {
                        console.error("Error fetching notifications:", error);
                    }
                }
            }
            
            fetchNotifications();
            
            // Cleanup function
            return () => {
                if (user && window.Echo) {
                    window.Echo.leave(`notifications.${user.id}`);
                    window.Echo.leave(`friend-requests.${user.id}`);
                }
            };
        }, [user, children, isPusherConnected]);
        
        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };
        
        const handleClose = () => {
            setAnchorEl(null);
        };
        
        return (
            <Badge
                badgeContent={notifs}
                classes={{ badge: classes.badge }}
                color="primary"
                overlap="rectangular"
            >
                <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    style={{ color: theme.primaryColor }}
                    onClick={handleClick}
                >
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    {user && children && children.length > 0 && (
                        <Badge
                            badgeContent={notifs}
                            classes={{ badge: classes.badge }}
                            color="primary"
                            overlap="rectangular"
                        >
                            <MenuItem onClick={() => setValue(2)}>
                                Friend Requests
                            </MenuItem>
                        </Badge>
                    )}
                    <MenuItem
                        onClick={() => {
                            window.localStorage.removeItem("JWT_TOKEN");
                            window.localStorage.removeItem("USER");
                            window.localStorage.removeItem("CHILDREN");
                            window.localStorage.removeItem("SETTINGS");
                            console.log(`Redirecting to: ${REACT_APP_API_URL.includes('localhost') ? 'http://localhost:3000/' : 'https://www.bridgepalkids.com/'}`);
                            window.location.href = REACT_APP_API_URL.includes('localhost') ? 'http://localhost:3000/' : 'https://www.bridgepalkids.com/';
                        }}
                    >
                        Logout
                    </MenuItem>
                </Menu>
            </Badge>
        );
    };
    
    return (
        <Grid container spacing={2}>
            <Grid item xs={9}>
                <img
                    style={{
                        marginLeft: "10px",
                        width: "256px",
                        height: "76px",
                    }}
                    src="/icons/BridgePal-Stacked.svg"
                    alt="BridgePal Logo"
                />
            </Grid>
            
            <Grid item xs={1}>
                <Badge
                    badgeContent={messageCount}
                    color="primary"
                    overlap="rectangular"
                    style={{ justifyContent: "flex-end" }}
                >
                    {settings && settings?.chat_allowed === 1 ? (
                        <img
                            style={{
                                width: "24px",
                                height: "24px",
                                paddingLeft: "76px",
                                paddingTop: "20px",
                                cursor: "pointer"
                            }}
                            onClick={() => history.push("/messenger")}
                            src="/icons/chat.png"
                            alt="Chat"
                        />
                    ) : null}
                </Badge>
            </Grid>
            <Grid
                item
                justifyContent="right"
                xs={1}
                spacing={3}
            >
                <BrAvatar
                    user={user}
                    theme={theme}
                    label={user.name}
                    onClick={() => history.push("/user/" + user.id)}
                    avatarStyles={{cursor: "pointer"}}
                />
            </Grid>
            <Grid item xs={1}>
                <SimpleMenu />
            </Grid>
        </Grid>
    );
};

export default Header;