import { useEffect } from 'react';
import Echo from 'laravel-echo';
import axios from 'axios';

const { REACT_APP_API_URL, REACT_APP_PUSHER_KEY, REACT_APP_PUSHER_CLUSTER } = process.env;

export function PusherManager({ user, onConnectionChange }) {
  useEffect(() => {
    console.log('PusherManager useEffect - User:', user?.id); // Debug log
    let echoInstance = null;
    
    const setupPusher = async () => {
      console.log('Setting up Pusher...');
      console.log('Setting up Pusher...');
      if (!user?.id || !window.localStorage.getItem("JWT_TOKEN")) {
        console.log('Missing user or token, skipping Pusher setup');
        return;
      }
      
      // Check if user is a mentor - handle differently
      const isMentor = user.roles?.some(role => role.name === "mentor");
      if (isMentor) {
        console.log('User is a mentor - using specialized Pusher setup');
      }
      
      // Prevent multiple setup attempts
      if (window.Echo) {
        console.log('Echo instance already exists, skipping setup');
        return;
      }
      
      try {
        const token = window.localStorage.getItem("JWT_TOKEN");
        
        const options = {
          broadcaster: 'pusher',
          key: REACT_APP_PUSHER_KEY,
          cluster: REACT_APP_PUSHER_CLUSTER,
          forceTLS: true,
          wsHost: process.env.NODE_ENV === 'production' ? 'ws-mt1.pusher.com' : undefined,
          wsPort: process.env.NODE_ENV === 'production' ? 443 : undefined,
          authEndpoint: `${REACT_APP_API_URL}/broadcasting/auth`,
          auth: {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Accept': 'application/json'
            }
          },
          encrypted: true,
          authorizer: (channel) => ({
            authorize: async (socketId, callback) => {
              try {
                const response = await axios.post(
                  `${REACT_APP_API_URL}/broadcasting/auth`,
                  {
                    socket_id: socketId,
                    channel_name: channel.name,
                  },
                  {
                    headers: {
                      'Authorization': `Bearer ${token}`,
                      'Accept': 'application/json',
                      'Content-Type': 'application/json'
                    }
                  }
                );
                
                if (!response?.data) throw new Error('Empty auth response');
                
                callback(null, response.data);
              } catch (error) {
                console.error('Channel authorization error:', error);
                callback(error);
              }
            }
          })
        };
        
        console.log('Creating Echo instance...');
        echoInstance = new Echo(options);
        window.Echo = echoInstance;
        
        // Set up connection handlers
        echoInstance.connector.pusher.connection.bind('connected', () => {
          console.log('Pusher connected successfully');
          subscribeToChannels(user.id);
          onConnectionChange?.('connected');
        });
        
        echoInstance.connector.pusher.connection.bind('disconnected', () => {
          console.log('Pusher disconnected');
          onConnectionChange?.('disconnected');
        });
      } catch (error) {
        console.error('Error setting up Pusher:', error);
        onConnectionChange?.('error', error);
      }
    };
    
    const subscribeToChannels = (userId) => {
      if (!echoInstance || !userId) {
        console.log('Echo not initialized or missing userId');
        return;
      }
      
      const isMentor = user.roles?.some(role => role.name === "mentor");
      
      // Customize channels based on user role
      let channels = [];
      if (isMentor) {
          // Only subscribe to necessary channels for mentors
          channels = [
            {name: `notifications.${userId}`, event: '.notification'}
          ];
      } else {
        channels = [
          {name: `notifications.${userId}`, event: '.notification'},
          {name: `messages.${userId}`, event: '.message'},
          {name: `friend-requests.${userId}`, event: '.friend-request'}
        ];
      }
      
      channels.forEach(({name, event}) => {
        // Don't resubscribe if already subscribed
        const channelName = `private-${name}`;
        if (echoInstance.connector.channels[channelName]) {
          console.log(`Already subscribed to ${channelName}`);
          return;
        }
        
        try {
          const channel = echoInstance.private(name);
          
          channel.error((error) => {
            console.error(`Error on channel ${name}:`, error);
          });
          
          channel.listen(event, (data) => {
            console.log(`Received ${event} on ${name}:`, data);
          });
          
          console.log(`Subscribed to ${channelName}`);
        } catch (error) {
          console.error(`Error subscribing to channel ${name}:`, error);
        }
      });
    };
  
  setupPusher();
  
  // Cleanup function
  return () => {
    if (echoInstance) {
      console.log('Cleaning up Pusher connection');
      echoInstance.disconnect();
    }
  };
  }, [user, onConnectionChange]);
  
  return null;
}