import axios from "axios";
import {  useState } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "../Input/Button";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";

const { REACT_APP_API_URL } = process.env;

export default function SelectNationalityModal(props) {
    const { user, theme } = props;
    const country_mapping = {
        AD: "AndorrA",
        AE: "United Arab Emirates",
        AF: "Afghanistan",
        AG: "Antigua and Barbuda",
        AI: "Anguilla",
        AL: "Albania",
        AM: "Armenia",
        AO: "Angola",
        AQ: "Antarctica",
        AR: "Argentina",
        AS: "American Samoa",
        AT: "Austria",
        AU: "Australia",
        AW: "Aruba",
        AX: "Åland Islands",
        AZ: "Azerbaijan",
        BA: "Bosnia and Herzegovina",
        BB: "Barbados",
        BD: "Bangladesh",
        BE: "Belgium",
        BF: "Burkina Faso",
        BG: "Bulgaria",
        BH: "Bahrain",
        BI: "Burundi",
        BJ: "Benin",
        BM: "Bermuda",
        BN: "Brunei Darussalam",
        BO: "Bolivia",
        BR: "Brazil",
        BS: "Bahamas",
        BT: "Bhutan",
        BV: "Bouvet Island",
        BW: "Botswana",
        BY: "Belarus",
        BZ: "Belize",
        CA: "Canada",
        CC: "Cocos (Keeling) Islands",
        CD: "Congo, The Democratic Republic of the",
        CF: "Central African Republic",
        CG: "Congo",
        CH: "Switzerland",
        CI: "Cote D'Ivoire",
        CK: "Cook Islands",
        CL: "Chile",
        CM: "Cameroon",
        CN: "China",
        CO: "Colombia",
        CR: "Costa Rica",
        CU: "Cuba",
        CV: "Cape Verde",
        CX: "Christmas Island",
        CY: "Cyprus",
        CZ: "Czech Republic",
        DE: "Germany",
        DJ: "Djibouti",
        DK: "Denmark",
        DM: "Dominica",
        DO: "Dominican Republic",
        DZ: "Algeria",
        EC: "Ecuador",
        EE: "Estonia",
        EG: "Egypt",
        EH: "Western Sahara",
        ER: "Eritrea",
        ES: "Spain",
        ET: "Ethiopia",
        FI: "Finland",
        FJ: "Fiji",
        FK: "Falkland Islands (Malvinas)",
        FM: "Micronesia, Federated States of",
        FO: "Faroe Islands",
        FR: "France",
        GA: "Gabon",
        GB: "United Kingdom",
        GD: "Grenada",
        GE: "Georgia",
        GF: "French Guiana",
        GG: "Guernsey",
        GH: "Ghana",
        GI: "Gibraltar",
        GL: "Greenland",
        GM: "Gambia",
        GN: "Guinea",
        GP: "Guadeloupe",
        GQ: "Equatorial Guinea",
        GR: "Greece",
        GS: "South Georgia and the South Sandwich Islands",
        GT: "Guatemala",
        GU: "Guam",
        GW: "Guinea-Bissau",
        GY: "Guyana",
        HK: "Hong Kong",
        HM: "Heard Island and Mcdonald Islands",
        HN: "Honduras",
        HR: "Croatia",
        HT: "Haiti",
        HU: "Hungary",
        ID: "Indonesia",
        IE: "Ireland",
        IL: "Israel",
        IM: "Isle of Man",
        IN: "India",
        IO: "British Indian Ocean Territory",
        IQ: "Iraq",
        IR: "Iran, Islamic Republic Of",
        IS: "Iceland",
        IT: "Italy",
        JE: "Jersey",
        JM: "Jamaica",
        JO: "Jordan",
        JP: "Japan",
        KE: "Kenya",
        KG: "Kyrgyzstan",
        KH: "Cambodia",
        KI: "Kiribati",
        KM: "Comoros",
        KN: "Saint Kitts and Nevis",
        KP: "Korea, Democratic People'S Republic of",
        KR: "Korea, Republic of",
        KW: "Kuwait",
        KY: "Cayman Islands",
        KZ: "Kazakhstan",
        LA: "Lao People'S Democratic Republic",
        LB: "Lebanon",
        LC: "Saint Lucia",
        LI: "Liechtenstein",
        LK: "Sri Lanka",
        LR: "Liberia",
        LS: "Lesotho",
        LT: "Lithuania",
        LU: "Luxembourg",
        LV: "Latvia",
        LY: "Libyan Arab Jamahiriya",
        MA: "Morocco",
        MC: "Monaco",
        MD: "Moldova, Republic of",
        MG: "Madagascar",
        MH: "Marshall Islands",
        MK: "Macedonia, The Former Yugoslav Republic of",
        ML: "Mali",
        MM: "Myanmar",
        MN: "Mongolia",
        MO: "Macao",
        MP: "Northern Mariana Islands",
        MQ: "Martinique",
        MR: "Mauritania",
        MS: "Montserrat",
        MT: "Malta",
        MU: "Mauritius",
        MV: "Maldives",
        MW: "Malawi",
        MX: "Mexico",
        MY: "Malaysia",
        MZ: "Mozambique",
        NA: "Namibia",
        NC: "New Caledonia",
        NE: "Niger",
        NF: "Norfolk Island",
        NG: "Nigeria",
        NI: "Nicaragua",
        NL: "Netherlands",
        NO: "Norway",
        NP: "Nepal",
        NR: "Nauru",
        NU: "Niue",
        NZ: "New Zealand",
        OM: "Oman",
        PA: "Panama",
        PE: "Peru",
        PF: "French Polynesia",
        PG: "Papua New Guinea",
        PH: "Philippines",
        PK: "Pakistan",
        PL: "Poland",
        PM: "Saint Pierre and Miquelon",
        PN: "Pitcairn",
        PR: "Puerto Rico",
        PS: "Palestinian Territory, Occupied",
        PT: "Portugal",
        PW: "Palau",
        PY: "Paraguay",
        QA: "Qatar",
        RE: "Reunion",
        RO: "Romania",
        RU: "Russian Federation",
        RW: "RWANDA",
        SA: "Saudi Arabia",
        SB: "Solomon Islands",
        SC: "Seychelles",
        SD: "Sudan",
        SE: "Sweden",
        SG: "Singapore",
        SH: "Saint Helena",
        SI: "Slovenia",
        SJ: "Svalbard and Jan Mayen",
        SK: "Slovakia",
        SL: "Sierra Leone",
        SM: "San Marino",
        SN: "Senegal",
        SO: "Somalia",
        SR: "Suriname",
        ST: "Sao Tome and Principe",
        SV: "El Salvador",
        SY: "Syrian Arab Republic",
        SZ: "Swaziland",
        TC: "Turks and Caicos Islands",
        TD: "Chad",
        TF: "French Southern Territories",
        TG: "Togo",
        TH: "Thailand",
        TJ: "Tajikistan",
        TK: "Tokelau",
        TL: "Timor-Leste",
        TM: "Turkmenistan",
        TN: "Tunisia",
        TO: "Tonga",
        TR: "Turkey",
        TT: "Trinidad and Tobago",
        TV: "Tuvalu",
        TW: "Taiwan, Province of China",
        TZ: "Tanzania, United Republic of",
        UA: "Ukraine",
        UG: "Uganda",
        US: "United States",
        UY: "Uruguay",
        UZ: "Uzbekistan",
        VA: "Holy See (Vatican City State)",
        VC: "Saint Vincent and the Grenadines",
        VE: "Venezuela",
        VG: "Virgin Islands, British",
        VI: "Virgin Islands, U.S.",
        VN: "Viet Nam",
        VU: "Vanuatu",
        WF: "Wallis and Futuna",
        WS: "Samoa",
        YE: "Yemen",
        YT: "Mayotte",
        ZA: "South Africa",
        ZM: "Zambia",
        ZW: "Zimbabwe"
    };
    
    const useStyles = makeStyles((theme2) => ({
        button: {
            width: "80px",
            height: "25px",
            borderRadius: "12px",
            fontSize: "10px",
            margin: "5px"
        },
        root: {
            minHeight: 500,
            minWidth: 258
        },
        bullet: {
            minHeight: "100px",
            margin: "0 2px",
            transform: "scale(0.8)"
        },
        title: {
            fontSize: 32,
            fontFamily: "GoodUnicorn",
            color: theme.primaryColor
        },
        margin: {
            margin: theme2.spacing(1)
        },
        modal: {
            backgroundColor: theme2.palette.background.paper,
            border: "2px solid #000",
            boxShadow: theme2.shadows[5],
            padding: theme2.spacing(2, 4, 3),
            position: "absolute",
            width: 300,
            maxHeight: 400,
            top: "30%",
            left: "30%",
            overflow: "scroll"
        },
        selectedNationality: {
            border: `3px solid ${theme.primaryColor}`, //#eb4c8a',
            borderRadius: "50%"
        },
        deselectedNationality: {
            border: "3px solid #FFF"
        },
        divider: {
            margin: 10
        },
    }));
    
    const [selectedNationality, setSelectedNationality] = useState(user.nationality);
    
    const setNationality = async () => {
        await axios(`${REACT_APP_API_URL}/user/${user.id}/nationality`, {
            method: "POST",
            data: { new_nationality: selectedNationality },
        });
        window.location.reload();
    };
    
    const classes = useStyles();
    
    const modalBody = (
        <div className={classes.modal}>
            <Grid container>
            {Object.keys(country_mapping).map((nationality) => {
                return (
                <Grid key={nationality} item xs={3}>
                    <img
                    key={nationality}
                    onClick={() => setSelectedNationality(nationality.toLowerCase())}
                    src={`https://flagcdn.com/32x24/${nationality.toLowerCase()}.png`}
                    className={
                        nationality.toLowerCase() == selectedNationality
                        ? classes.selectedNationality
                        : classes.unSelectedNationality
                    }
                    style={{ width: "32px", height: "24px", margin: "5px" }}
                    />
                </Grid>
                );
            })}
            
            <div style={{ width: "100%", textAlign: "center" }}>
                <Button theme={theme} onClick={setNationality}>
                {" "}
                Update{" "}
                </Button>
            </div>
            </Grid>
        </div>
    );
    
    return (
        <Modal
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            {modalBody}
        </Modal>
    );
}
