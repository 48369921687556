import React, { useEffect, useState } from "react";
import BrAvatar from "./Avatar";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import BootstrapInput from "../Input/BootstrapInput";
import AlertDialogSlide from "../Input/TransitionDialog";
import CheckCircle from "@material-ui/icons/CheckCircle";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Badge from "@material-ui/core/Badge";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";


const { REACT_APP_API_URL } = process.env;


export default function LifeTopicPost(props) {
    const { user, isParent, isMentor, theme, post, removePost, showNotification } = props;
    
    const useStyles = makeStyles((theme) => ({
        badge: {
            right: 10,
            top: 11,
            border: `2px solid ${theme.primaryColor}`,
            padding: "0 4px"
        },
        notif_badge: {
            right: 1,
            top: 15,
            border: `2px solid ${theme.primaryColor}`,
            padding: "0 4px"
        },
        root: {
            flexGrow: 1
        },
        topNav: {
            height: "75px",
            width: "100%"
        },
        control: {
            padding: theme.spacing(2),
        }
    }));
    
    const classes = useStyles();
    const history = useHistory();
    
    const handleCommentChange = (e) => {
        if (e.target.value.slice(-1) === "\n") {
            return;
        } else {
            setComment({ id:comment.id, content:e.target.value });
        }
    };
    
    const likePost = async (e) => {
        if(isParent) return;
        
        // If user has already liked the post, then remove the like, else add the like
        if (likes.find((like) => like.user_id === user.id)) {
            await axios(`${REACT_APP_API_URL}/life_topic_posts/like/${post.id}`, {
                method: "DELETE",
                data: comment
            });
            setLikes([...likes.filter((like) => like.user_id !== user.id)]);
        } else {
            await axios(`${REACT_APP_API_URL}/life_topic_posts/like/${post.id}`, {
                method: "POST",
                data: comment
            });
            setLikes([...likes, { user_id: user.id, life_topic_post_id: post.id }]);
        }
    };
    
    const removePostAsync = async (e) => {
        try {
            const response = await axios(`${REACT_APP_API_URL}/life_topic_posts/remove/${post.id}`, {
                method: "DELETE"
            });
            
            // Check if the API returned success status
            if (response.data.success) {
                console.log("Post successfully removed on server");
                
                // Call the parent component's removePost function to update UI
                removePost(post.id);
                
                // If needed, additionally, trigger a fresh load of all posts
                // if (props.refreshPosts) {
                //     props.refreshPosts();
                // }
                
                // Optional: Show a success notification
                if (props.showNotification) {
                    props.showNotification("Post removed successfully", "success");
                }
            } else {
                console.error("Failed to remove post:", response.data);
                if (props.showNotification) {
                    props.showNotification("Failed to remove post", "error");
                }
            }
        } catch (error) {
            console.error("Error removing post:", error);
            if (props.showNotification) {
                props.showNotification("Error removing post", "error");
            }
        }
    };
    
    const approvePostAsync = async (e) => {
        await axios(`${REACT_APP_API_URL}/life_topic_posts/approve/${post.id}`, {
            method: "POST"
        });
        setApproved(true);
        showNotification("post approved", "success");
    };
    
    const approveCommentAsync = async (comment) => {
        await axios(
            `${REACT_APP_API_URL}/life_topic_posts/comment/approve/${comment.id}`,
            { method: "POST" }
        );
        setApproved(true);
        showNotification("comment approved", "success");
    };
    
    const removeCommentAsync = async (comment) => {
        await axios(`${REACT_APP_API_URL}/life_topic_posts/comment/${comment.id}`, {
            method: "DELETE",
            data: { content: comment },
        });
        removeComment(comment.id);
    };
    
    const setCommentEditing = (comment) => {
        removeComment(comment.id);
        setComment(comment);
    }
    const setPostEditing = () => {
        setEditing(!editing);
    }
    
    const updatePost = async () => {
        axios
            .put(`${REACT_APP_API_URL}/life_topic_posts/` + post.id, {message:message})
            .then((result) => {
                showNotification("success", "success");
                setEditing(!editing);
            })
            .catch(({ message, response }) => {
                showNotification(response.data.message, "error");
            });
    };
    
    const awardPost = async (e) => {
        if (!isMentor) return;
        
        // If the post has already been awarded, do nothing, otherwise add the award
        if (awards.filter((award) => award.user_id == user.id).length === 0) {
            const new_award = await axios(
                `${REACT_APP_API_URL}/life_topic_posts/award/${post.id}`,
                { method: "POST" }
            );
            setAwards([...awards, new_award.data]);
        }
    };
    
    const handleKeyDown = async (e) => {
        if (e.key === "Enter") {
            if (e.shiftKey) {
                setComment({content:comment + "\n"});
            } else {
                let attemptComment = await axios(
                    `${REACT_APP_API_URL}/life_topic_posts/comment/${post.id}`,
                    { method: "POST", data: { comment } }
                );
                if (attemptComment.status === 400) {
                    showNotification("Your message was rejected", "warning");
                    return;
                }
                setComments([...comments, { user, content: comment.content, id:attemptComment.data.id }]);
                setComment({content: ""});
            }
        }
    };
    
    const SimpleMenu = (props) => {
        const [anchorEl, setAnchorEl] = useState(null);
        const [notifs, setNotifCount] = useState(null);
        const { comment } = props;
        
        useEffect(async () => {
            // Skip Pusher subscription for mentor users completely
            if (isMentor) {
                console.log("Skipping notification subscription for mentor");
                return; // Early return to avoid any subscription attempts
            }
            
            // Only proceed with subscription for non-mentor users
            if (isParent) {
                const fetchNotifications = async () => {
                    try {
                        const result = await axios(`${REACT_APP_API_URL}/pending_friends/${user.id}`);
                        setNotifCount(result.data.length);
                    } catch (error) {
                        console.error("Error fetching notifications:", error);
                    }
                };
                
                fetchNotifications();
                
                // Only set up Echo listener for non-mentors
                console.log("🚀 ~ useEffect ~ user?.parent:", user);
                try {
                    if (window.Echo) {
                        // Use notifications channel instead of notifs
                        window.Echo.private(`notifications.${user?.id}`).listen(
                            "SentNotification",
                            (e) => {
                                console.log(e);
                                setNotifCount(e.data.count);
                            }
                        );
                    }
                } catch (error) {
                    console.error("Error setting up Echo listener:", error);
                }
            }
        }, []);
        
        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };
        
        const handleClose = () => {
            setAnchorEl(null);
        };
        
        window.Echo.private(`notifs.${user.id}`).listen("SentNotif", (e) => {
            setNotifCount((prevState) => [...prevState, e.notifs]);
        });
        
        // Skip notification menu for mentor users
        if(isMentor) {
            console.log(`Skipping notification menu for mentor ${user.id}.`);
            return null;
        }
        
        return (
            <Badge
                classes={{ badge: classes.badge }}
                color="primary"
                overlap="rectangular"
            >
                <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    style={{ color: theme.primaryColor }}
                    onClick={handleClick}
                >
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem
                        onClick={(e) => setCommentEditing(comment)}
                    >
                        Edit
                    </MenuItem>
                    <MenuItem
                        onClick={() => removeCommentAsync(comment)}
                    >
                        Delete
                    </MenuItem>
                </Menu>
            </Badge>
        );
    };
    
    const removeComment = (comment_id) => {
        setComments([...comments.filter((comment) => {
            return comment_id !== comment.id;
        })]);
    };
    
    const [comment, setComment] = useState({content:''});
    const [comments, setComments] = useState([]);
    const [likes, setLikes] = useState([]);
    const [awards, setAwards] = useState(post.awards);
    const [approved, setApproved] = useState(post.approved);
    const [message, setMessage] = useState(post.message);
    const [editing, setEditing] = useState(false);
    
    useEffect(() => {
        setComments(post.comments);
        setLikes(post.likes);
    }, [post]);
    
    const usePointerCursor = isParent || isMentor ? "auto" : "pointer";
    
    return (
        <>
        <Grid item xs={4} style={{ zIndex: 1 }}>
            <div style={{ width: '100%' }}>
                <Card style={{ marginTop: "100px", width: "100%" }}>
                    <Grid container>
                        <Grid item xs={9}>
                            <span
                                style={{
                                    color: theme.primaryColor,
                                    cursor: "pointer",
                                }}
                                onClick={() => history.push(`/user/${post.user_upload.user.id}`)}
                            >
                                <BrAvatar theme={theme} user={post.user_upload.user} />
                                {/* TODO: Username links to their profile at /user/{user.id} */}
                                {post.user_upload.user?.name[0].toUpperCase() + post.user_upload.user?.name.slice(1) ?? "User"}
                            </span>
                        </Grid>
                        
                        <Grid item xs={3}>
                            {isMentor && !approved ? (
                            <span style={{ textAlign: "right" }}>
                                {" "}
                                <IconButton
                                    onClick={approvePostAsync}
                                    style={{ width: "45px" }}
                                >
                                <CheckCircle />
                                </IconButton>{" "}
                            </span>
                            ) : null}
                            
                            {user.id === post.user_upload.user.id  ? (
                                    <span style={{ textAlign: "right" }}>
                                        {" "}
                                        <IconButton
                                        onClick={setPostEditing}
                                        style={{ width: "45px" }}
                                        >
                                        <EditIcon/>
                                        </IconButton>{" "}
                                    </span>
                                ) : null
                            }
                            
                            {/* If the post belongs to the user OR the user is a mentor, show the delete icon */}
                            {user.id === post.user_upload.user.id || isMentor ? (
                            <span style={{ textAlign: "right" }}>
                                {" "}
                                <AlertDialogSlide 
                                    action={removePostAsync}
                                    trigger={(handleClick) => (
                                        <AlertDialogSlide 
                                            action={removePostAsync}
                                            trigger={(handleClick) => (
                                                <span 
                                                    onClick={handleClick}
                                                    style={{ 
                                                        cursor: 'pointer',
                                                        display: 'inline-flex',
                                                        padding: '8px'
                                                    }}
                                                >
                                                    <DeleteIcon 
                                                        style={{ 
                                                            width: "24px",
                                                            height: "24px",
                                                            color: 'red' 
                                                        }} 
                                                    />
                                                </span>
                                            )}
                                        />
                                    )}
                                />
                            </span>
                            ) : null}
                        </Grid>
                    </Grid>
                    
                    <div style={{ margin: "auto", textAlign: "center" }}>
                        {editing ?
                        <>
                            <BootstrapInput onChange={ (event) => setMessage(event.target.value) } value={message} />
                            <CheckCircle
                                style={{ cursor: "pointer" }}
                                onClick={() => updatePost()}
                            />{" "}
                        </> :  <Typography style={{textAlign:'left', margin:5}}> {message} </Typography>
                        }
                    </div>
                    <CardMedia
                        style={{ width: "100%", objectFit: "fill",  margin: "auto" }}
                        component="img"
                        onClick={() => {
                            window.open(`${post.user_upload.url}`, "_blank");
                        }}
                        image={`${post.user_upload.url}`}
                        title={`Image Uploaded by ${post.user_upload.user.name[0].toUpperCase() + post.user_upload.user.name.slice(1) ?? "User"}`}
                    />
                    <div
                        style={{
                            height: "50px",
                            whiteSpace: "nobreak",
                            marginTop: "10px",
                            textAlign: "center"
                        }}
                    >
                    {isMentor && (
                        <>
                            <img
                                onClick={awardPost}
                                style={{
                                    height: "24px",
                                    width: "24px",
                                    marginLeft: "3%",
                                    cursor: "pointer"
                                }}
                                src="/icons/002-trophy.png"
                            />
                            <span style={{ marginLeft: "1%" }}>{awards.length}</span>
                        </>
                    )}
                    <img
                        onClick={likePost}
                        style={{
                            height: "24px",
                            width: "24px",
                            marginLeft: "3%",
                            cursor: usePointerCursor
                        }}
                        src="/icons/001-like.png"
                    />
                    <span style={{ marginLeft: "1%" }}>{likes.length}</span>
                    <span style={{ marginLeft: "70%" }}> {comments.length} </span>
                    <img
                        style={{
                            height: "24px",
                            width: "24px",
                            marginLeft: "2%",
                            cursor: usePointerCursor
                        }}
                        src="/icons/001-speech-bubble.png"
                    />
                    </div>
                    <Divider style={{ width: "100%" }} />
                    <Grid container>
                        {/* TODO: Should mentors be able to comment? */}
                        {!isParent ? (
                            <>
                            <Grid item xs={1}>
                                <BrAvatar theme={theme} user={user} />
                            </Grid>
                            <Grid item xs={11}>
                                <Paper
                                    elevation={0}
                                    style={{
                                        width: "95%",
                                        height: "100%",
                                        textAlign: "center",
                                        marginTop: "5px",
                                        backgroundColor: "#f2f2f2"
                                    }}
                            >
                            <TextField
                                style={{ width: "95%" }}
                                id="status"
                                variant={"filled"}
                                value={comment.content}
                                multiline
                                InputLabelProps={{
                                    shrink: "true"
                                }}
                                onChange={handleCommentChange}
                                onKeyDown={handleKeyDown}
                                InputProps={{
                                    style: {
                                        backgroundColor: "#f2f2f2",
                                        padding: 0,
                                        after: {
                                            content: "none"
                                        },
                                        before: { content: "none" }
                                    },
                                    shrink: "true",
                                    disableUnderline: true
                                }}
                                inputProps={{
                                    placeholder: "Write a comment",
                                    style: {
                                        paddingTop: "13px",
                                        paddingBottom: "0px",
                                        backgroundColor: "#f2f2f2"
                                    }
                                }}
                            />
                            </Paper>
                        </Grid>
                        </>
                    ) : null}
                    
                    <Grid item xs={12} style={{ height: "10px" }}></Grid>
                    
                    {comments.length > 0
                        ? comments.map((comment, idx) => (
                            <React.Fragment key={comment.id || `comment-${idx}`}>
                                <Grid item xs={1}>
                                <BrAvatar theme={theme} user={comment.user} />
                                </Grid>
                                <Grid item xs={10}>
                                <Card style={{ backgroundColor: "#f2f2f2", marginTop: "5px", width: "95%" }}>
                                    <b style={{ margin: "3px", textDecoration: "underline", color: theme.primaryColor, cursor: "pointer" }}
                                    onClick={() => { history.push(`/user/${comment.user.id}`); }}>
                                    {comment.user?.name ?? "User"}
                                    </b>
                                    <br />
                                    <p style={{ margin: "3px" }}>{comment.content}</p>
                                </Card>
                                </Grid>
                                <Grid item xs={1}>
                                {isMentor && !comment.approved ? (
                                    <span style={{ textAlign: "right" }}>
                                    <CheckCircle style={{ cursor: "pointer" }} onClick={() => approveCommentAsync(comment)} />
                                    </span>
                                ) : null}
                                {user?.id === comment.user?.id || isMentor ? (
                                    <span style={{ textAlign: "right" }}>
                                    <SimpleMenu comment={comment} />
                                    </span>
                                ) : null}
                                </Grid>
                            </React.Fragment>
                            ))
                        : null}
                    </Grid>
                </Card>
            </div>
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={4} />
    </>
    );
};